import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./index.css";

const RouteInfoModal = props => {
  const { show, onClose, onSave, data } = props;

  const defaultValue = {
    name: "",
    phone: "",
    notes: "",
  };
  const [formValues, setFormValues] = useState(defaultValue);

  useEffect(() => {
    if (show) {
      setFormValues({
        name: data?.contact_info?.name,
        phone: data?.contact_info?.phone,
        notes: data?.contact_info?.notes,
      })
    }
  }, [data?.contact_info?.name, data?.contact_info?.notes, data?.contact_info?.phone, show]);

  const handleSubmit = e => {
    console.log(formValues)
    if(formValues.name.trim().length < 1 ||
      formValues.phone.trim().length < 1 ||
      formValues.notes.trim().length < 1
    ) {
      return;
    }

    const contact_info = {
      name: formValues.name,
      phone: formValues.phone,
      notes: formValues.notes,
    };

    const location_data = {
      ...data,
      contact_info
    }

    onSave(location_data);
  };

  const closeModal = e => {
    props.onClose();
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // PricingCalculation(name, value);
  };

  if (!show) return null;

  return (
    <div className="my_modal-outside">
      <div class="my_modal-content">
        <div class="my_modal-header">
          <span class="close" onClick={onClose}>
            &times;
          </span>
          <div className="title">Contact Information</div>
        </div>

        <div class="my_modal-body">
          <div className="my_contact_section">
            <label>Contact name</label>

            <input
              required
              type="text"
              placeholder="Contact name"
              onChange={handleInputChange}
              name="name"
              value={formValues.name}
            />
          </div>

          <div className="my_contact_section">
            <label>Contact phone</label>

            <input
              type="tel"
              placeholder="Phone number"
              onChange={handleInputChange}
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              name="phone"
              value={formValues.phone}
            />
          </div>

          <div className="my_notes_section">
            <label>
              Notes<span style={{ color: "red" }}></span>:
            </label>
            <textarea
              required
              type="text"
              placeholder="Notes"
              onChange={handleInputChange}
              name="notes"
              rows="5"
              value={formValues.notes}
            />
          </div>
        </div>

        <div class="my_modal-footer">
          <div className="modal_buttons">
            <Button onClick={handleSubmit} className="active">
              Save{" "}
            </Button>
            <Button className="active_close" onClick={closeModal}>
              {" "}
              Close{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteInfoModal;
