import React from "react";
import "./ContactUs.css";
import { Button, Modal, Form, Container, Row } from "react-bootstrap";

const ContactUs = (props) => {
    return (
        <React.Fragment>

            <Modal
                show={props.isOpenModal}
                onHide={() => props.handleCloseModal("isOpenContactUsModal")}
                backdrop="static"
                keyboard={false}
                centered
                className="kiwi-modal"
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Container>
                        <Row>
                            <div className="contact-us d-flex align-items-center">
                                <div className="container h-100">
                                    <div className="row flex-column-reverse flex-lg-row">
                                        {/* Left Section Start (Contact Us Form) */}
                                        <div className="col-sm-12 col-md-12 col-lg-8">
                                            <div className="form">
                                                <Form onSubmit={() => {
                                                }}>
                                                    <Form.Group controlId="contact_name">
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            onChange={(e) =>
                                                                props.handleStateChange("contact_name", e.target.value)
                                                            }
                                                            placeholder="Name"
                                                        />
                                                        <Form.Label>Name</Form.Label>
                                                    </Form.Group>

                                                    <Form.Group controlId="contact_email">
                                                        <Form.Control
                                                            required
                                                            type="email"
                                                            onChange={(e) =>
                                                                props.handleStateChange("contact_email", e.target.value)
                                                            }
                                                            placeholder="Email address"
                                                        />
                                                        <Form.Label>Email address</Form.Label>
                                                    </Form.Group>

                                                    <Form.Group controlId="phoneNumber">
                                                        <Form.Control
                                                            required
                                                            type="tel"
                                                            onChange={(e) =>
                                                                props.handleStateChange("phoneNumber", e.target.value)
                                                            }
                                                            pattern="[0-9]{3}[0-9]{2}[0-9]{3}"
                                                            placeholder="Phone number"
                                                        />
                                                        <Form.Label>Phone number</Form.Label>
                                                    </Form.Group>

                                                    <Form.Group controlId="message">
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            onChange={(e) =>
                                                                props.handleStateChange("message", e.target.value)
                                                            }
                                                            placeholder="Message"
                                                        />
                                                        <Form.Label>Message</Form.Label>
                                                    </Form.Group>


                                                    <Form.Group controlId="contact_captcha">
                                                        <div className="captcha justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <div className="spinner">
                                                                    <label>
                                                                        <input type="checkbox" />
                                                                        <span className="checkmark"><span>&nbsp;</span></span>
                                                                    </label>
                                                                </div>
                                                                <div className="text">
                                                                    I'm not a robot
                                                                </div>
                                                            </div>
                                                            <div className="logo">
                                                                <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/" alt="captcha" />
                                                                <p>reCAPTCHA</p>
                                                                <small>Privacy - Terms</small>
                                                            </div>
                                                        </div>
                                                    </Form.Group>

                                                    <Button type="submit" className="btn-green ">
                                                        SUBMIT
                                                    </Button>
                                                </Form>
                                            </div>
                                        </div>

                                        {/* Right Section Start */}
                                        <div className="col-sm-12 col-md-12 col-lg-4">
                                            <div className="d-flex flex-column mt-0 mt-sm-5 align-items-center align-items-lg-start">
                                                <span className="contact-us-header">Contact Us!</span>
                                                <span className="contact-us-subheader">
                                                    We would love to help!
                                                </span>
                                                <span className="contact-us-subheader d-none d-sm-flex">
                                                    03 555 5555
                                                    <br />
                                                    iwi@kiwi.com
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container >
                </Modal.Body >
            </Modal >
        </React.Fragment >
    );
};

export default ContactUs;
