import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Form } from "react-bootstrap";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const userId = localStorage.getItem("kiwi-uid");
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        var uid = user.uid;
        console.log("onAuthStateChanged=======", user);
      } else {
        // User is signed out
        console.log("onAuthStateChanged=======", user);
        history.push("/signin");
      }
    });
  }, []);

  useEffect(() => {
    if (!userData || !userId) {
      history.push("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userId]);

  const [date, setDate] = useState(null);

  const handleDateChange = e => {
    setDate(e.target.value);
  };

  return (
    <div>
      <div className="middel_component">
        <section className="middel_component_header">
          <div className="dashboard">
            <i className="fa-solid fa-house"></i>
            <p>Dashboard</p>
          </div>
          <div className="overview_text"></div>
        </section>

        <div className="order_button">
          <h4>Orders</h4>
        </div>
        {/* <div>
          <Form.Group className="dashboar_date_picker" controlId="dob">
            <div>
              <Form.Label>Select Date :</Form.Label>
            </div>
            <div>
              <Form.Control
                placeholder="dd/mm/yyyy"
                data-date-format="mm/dd/yyyy"
                value={date}
                onChange={e => handleDateChange(e)}
                type="date"
                name="dob"
              />
            </div>
          </Form.Group>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
