import React from "react";
import "./Sender.css";
import Sender1 from "../../../../../Assets/Images/sender-1.png";
import Sender2 from "../../../../../Assets/Images/seder-2.png";
import Sender3 from "../../../../../Assets/Images/sender-3.png";
import Driver1 from "../../../../../Assets/Images/Driver1.png";
import Driver2 from "../../../../../Assets/Images/Driver2.png";
import Driver3 from "../../../../../Assets/Images/Driver3.png";
import AppStore from "../../../../../Assets/Images/appstore.svg";
import GooglePlay from "../../../../../Assets/Images/googleplay.svg";
import AddressInfoForm from '../AddressInfo/AddressInfoForm';
import Carousel from "react-bootstrap/Carousel";

const Sender = (props) => {
    const { activeTab, activeIndex, handleChangeSlide } = props;
    return (
        <div>
            <div className="sender d-flex align-items-center">
                <div className="container h-100">
                    <div className="d-flex flex-column align-items-center">
                        <div className="d-flex sender-tab">
                            <div
                                className={`${activeTab === 'Sender' ? 'active' : ''} sender-name`}
                                onClick={() => props.handleChangeTab('Sender')}
                            >
                                Sender
                            </div>
                            <div
                                className={`${activeTab === 'Driver' ? 'active' : ''} sender-name`}
                                onClick={() => props.handleChangeTab('Driver')}
                            >
                                Driver
                            </div>
                        </div>

                        {activeTab === "Sender" ? (
                            <div className="d-sm-flex d-none flex-column flex-lg-row">
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Sender1} alt="sender1"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Order delivery in few seconds
                                    </span>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Sender2} alt="sender2"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Track your package easily
                                    </span>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Sender3} alt="sender3"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Enjoy the cheapest same day delivery!
                                    </span>
                                </div>
                            </div>
                              ) : (
                            <div className="d-sm-flex d-none flex-column flex-lg-row">
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Driver1} alt="driver1"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Pick the offer that fits you easily
                                    </span>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Driver2} alt="driver2"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Make it safe and easy with Kiwi
                                    </span>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <img src={Driver3} alt="driver3"/>
                                    <span className="sender-item-name d-none d-sm-block">
                                      Earn some extra money on the way!
                                    </span>
                                </div>
                            </div>
                        )}

                        {activeTab === "Sender" ? (
                            <Carousel className="sender-slider d-sm-none d-block" activeIndex={activeIndex} controls={false} onSelect={handleChangeSlide}>
                                <Carousel.Item className={activeIndex === 0 ? 'd-flex' : ""}>
                                    <div className="mr-1">
                                        <img src={Sender1} alt="sender1"/>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item className={activeIndex === 1 ? 'd-flex' : ""}>
                                    <div className="mr-1">
                                        <img src={Sender2} alt="sender1"/>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item className={activeIndex === 2 ? 'd-flex' : ""}>
                                    <div className="mr-1">
                                        <img src={Sender3} alt="sender1"/>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        ) : (<Carousel className="sender-driver d-sm-none d-block" activeIndex={activeIndex} controls={false} onSelect={handleChangeSlide}>
                            <Carousel.Item className={activeIndex === 0 ? 'd-flex' : ""}>
                                <div className="mr-1">
                                    <img src={Driver1} alt="driver1"/>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className={activeIndex === 1 ? 'd-flex' : ""}>
                                <div className="mr-1">
                                    <img src={Driver2} alt="driver1"/>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className={activeIndex === 2 ? 'd-flex' : ""}>
                                <div className="mr-1">
                                    <img src={Driver3} alt="driver1"/>
                                </div>
                            </Carousel.Item>
                        </Carousel>)}


                        <div className="d-none d-sm-flex sender-btn flex-column flex-sm-row">
                            <div className="store-btn mb-2 mb-sm-0">
                                <img src={AppStore} alt="appstore icon"/>
                            </div>
                            <div className="store-btn">
                                <img src={GooglePlay} alt="googleplay icon"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-5 d-block d-sm-none">
                <AddressInfoForm
                    addressData={props.addressData}
                    handleStateChange={props.handleStateChange}
                    isOpenSenderModal={props.isOpenSenderModal}
                    isOpenReceiverModal={props.isOpenReceiverModal}
                    handleToggleSenderDetailPopup={props.handleToggleSenderDetailPopup}
                    handleToggleReceiverDetailPopup={props.handleToggleReceiverDetailPopup}
                    senderPopupData={props.senderPopupData}
                    receiverPopupData={props.receiverPopupData}
                    handleSenderPopupStateChange={props.handleSenderPopupStateChange}
                    handleReceiverPopupStateChange={props.handleReceiverPopupStateChange}
                />
            </div>
        </div>
    );
};

export default Sender;
