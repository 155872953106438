import React from "react";
import Logo from "../../../../src/Assets/Images/Logo.png";
import HamburgerMenu from "../../../../src/Assets/Images/hamburger menu.svg";
import share from "../../../../src/Assets/icon/share.png";
import { useHistory } from 'react-router-dom'

const HeaderLayout = (props) => {
  const { activeHeader, signOutFunc, Headertest } = props;
  const history = useHistory();

  const RightLayout = () => {
    return (
      <div>
        <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={HamburgerMenu} alt="hamBurgerMenu" />
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0 align-items-sm-center">
              <li className={`${activeHeader === 'HomeDelivery' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('HomeDelivery')}>
                  Order Delivery
                </span>
              </li>
              <li className={`${activeHeader === 'App' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('App')}>
                  App
                </span>
              </li>
              <li className={`${activeHeader === 'TrackYourOrder' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('TrackYourOrder')}>
                  Track Your Order
                </span>
              </li>
              <li className={`${activeHeader === 'ContactUs' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('ContactUs')}>
                  Contact Us
                </span>
              </li>
              <li className={`${activeHeader === 'JoinUs' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('JoinUs')}>
                  Join Us
                </span>
              </li>
              <li className={`${activeHeader === 'SignUp' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => history.push('/signup')}>
                  Sign Up
                </span>
              </li>
              <li className={`${activeHeader === 'signIn' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => history.push('/signin')}>
                  Sign In
                </span>
              </li>
              <li className={`${activeHeader === 'share' ? 'active' : ''} nav-item`}>
                <span className="nav-link" onClick={() => props.handleChangeHeaderTab('share')}>
                  <img src={share} alt="share" />
                </span>
              </li>
            </ul>
          </div>
      </div>
    )
  }
  return (
    <div>
      <div className="navbar navbar-expand-lg">
        <div className="container">
          <span className="navbar-brand" onClick={() => history.push('/')}>
            <img src={Logo} alt="logo" height="42" />
          </span>
          {/* {RightLayout()} */}
        </div>
      </div>
    </div >
  );
};

export default HeaderLayout;
