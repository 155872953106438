import React from "react";
import { Dropdown } from "react-bootstrap";
import "./DashboardHeader.css";
import firebase from "firebase/app";
import Profile from "../../../../Assets/Images/profile_img_logo.jpg";
import { useHistory } from "react-router-dom";

const DashboardHeader = props => {
  const history = useHistory();
  function handleLogout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("kiwi-uid");
        localStorage.removeItem("userInfo");
        history.push("/signin");
      })
      .catch(error => {});
  }
  return (
    <section className={props.isActive ? "active_top_bar" : "top_bar"}>
      <div className="top_bar_left_menu">
        <div className="hamburger">
          <i
            className="fa-solid fa-bars"
            onClick={() => props.handleDrawer()}
          ></i>
        </div>
      </div>
      <div className="top_bar_right_menu">
        <div className="header_icons"></div>
        <div className="drop_down">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img
                className="header_profile_img"
                src={Profile}
                alt="header"
              ></img>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>
                <i className="fa-solid fa-power-off"></i>Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </section>
  );
};
export default DashboardHeader;
