import React from "react";
import "./Feature.css";
import LowestPrice from "../../../../../Assets/Images/lowest_price.svg";
import DeliveryTruck from "../../../../../Assets/Images/delivery_truck.svg";
import QualityCheck from "../../../../../Assets/Images/quality_check.svg";
import Parcel from "../../../../../Assets/Images/parcel.svg";

const Feature = (props) => {
  return (
    <div>
      <div className="container h-100">
        <div className="feature ">
          <div className="row align-items-center">
            <div className="col-sm-12 mb-4 mb-lg-0 col-lg-3">
              <div className="feature-item d-flex flex-column flex-lg-row align-items-center">
                <img src={LowestPrice} alt="lowest" />
                <span className="ml-2 feature-name">Lowest Prices</span>
              </div>
            </div>
            <div className="col-sm-12 mb-4 mb-lg-0 col-lg-3">
              <div className="feature-item d-flex flex-column flex-lg-row align-items-center">
                <img src={DeliveryTruck} alt="lowest" />
                <span className="ml-2 feature-name">
                  Same Day
                  <br />
                  Delivery
                </span>
              </div>
            </div>
            <div className="col-sm-12 mb-4 mb-lg-0 col-lg-3">
              <div className="feature-item d-flex flex-column flex-lg-row align-items-center">
                <img src={QualityCheck} alt="quality-check" />
                <span className="ml-2 feature-name">Quality Service</span>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3">
              <div className="feature-item d-flex flex-column flex-lg-row align-items-center">
                <img src={Parcel} alt="parcel" />
                <span className="ml-2 feature-name">Parcel Protection</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
