import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import firebase from "firebase/app";
import { db } from "../../../../Config/firebase";
import "./AddOrder.css";
import LocationAutocomplate from "./LocationAutocomplate";
import Spinner from "react-bootstrap/Spinner";
import PaypalModal from "./Paypal";
import { GetSortOrder, getDistanceMatrix } from "../../../../utils/common";
import RouteInfoModal from './RouteInfoModal';

const PricePolicyExample = {
  percent_3kg_more: "0.1",
  percent_5kg_more: "0.2",
  percent_10kg_more: "0.4",
  price_per_1kg: "2",
  price_per_1km: "1",
};

const AddOrder = props => {
  const { orderData, setOrderData } = props;
  const userid = localStorage.getItem("kiwi-uid");
  const packageDetailsRef = db.collection("packageDetails");
  const packagePricing = db.collection("packagePricing");
  const [paypalModalShow, setPaypalModalShow] = useState(false);
  const defaultValue = {
    orderid: new Date().getTime(),
    date: "",
    priority: "",
    from: "",
    to: "",
    notes: "",
    vehicle: "",
    weight: "",
    price: "",
    priceYouPay: "",
  };
  const [motobikePolicy, setMotobikePolicy] = useState({});
  const [carPolicy, setCarPolicy] = useState({});
  const [vanPolicy, setVanPolicy] = useState({});

  const [errorMsg, setErrorMsg] = useState("");
  const [totalKilometers, setTotalKilometers] = useState(0);
  const [detailKilometers, setDetailKilometers] = useState([]);
  const [formValues, setFormValues] = useState(defaultValue);
  const [formError, setFormError] = useState(false);

  const [price, setPrice] = useState(0);
  const [youPay, setYouPay] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showRouteInfoModal, setShowRouteInfoModal] = useState(false);
  const [currentLocationData, setCurrentLocationData] = useState(null);
  
  let showDetailKilometers = "";
  let errorKilometer = false;
  detailKilometers.map((p, index) => {
    if (index === 0) showDetailKilometers = "( ";
    showDetailKilometers += (index > 0 ? ", " : "") + p.distance;
    if (index === detailKilometers.length - 1) showDetailKilometers += " )";
    if (!p.distance || p.distance === -1) errorKilometer = true;
  });

  const defaultLocationList = [
    {
      id: new Date().getTime(),
      location: "",
      lat: null,
      lng: null,
      orderType: "",
      distance: "",
      duration: "",
      contact_info: {
        name: "",
        phone: "",
        notes: "",
      }
    },
    {
      id: new Date().getTime() + 1,
      location: "",
      lat: null,
      lng: null,
      orderType: "",
      distance: "",
      duration: "",
      contact_info: {
        name: "",
        phone: "",
        notes: "",
      }
    },
  ];

  const [locationList, setLocationList] = useState(defaultLocationList);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (props.show) {
      const initValue = {
        orderid: new Date().getTime(),
        date: formatDate(new Date()),
        priority: "",
        from: "",
        to: "",
        notes: "",
        vehicle: "",
        weight: "",
        price: "",
        priceYouPay: "",
      };
      setFormValues(initValue);
    }
  }, [props.show]);

  useEffect(() => {
    PricingCalculation(formValues.vehicle, formValues.weight, totalKilometers);
  }, [formValues.vehicle, formValues.weight, totalKilometers]);

  useEffect(() => {
    if (checkValidate()) {
      setFormError(true);
      setErrorMsg("Please fill in all required fields");
    } else {
      setFormError(false);
      setErrorMsg("");
    }
  }, [
    formValues.date,
    formValues.priority,
    formValues.to,
    formValues.from,
    formValues.vehicle,
    formValues.weight,
    formValues.price,
    formValues.orderid,
  ]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const PricingCalculation = (vehicle, kg, km) => {
    const weight = parseFloat(kg === ">10kg" ? 100 : kg);
    const kilometer = parseFloat(km);
    let price = 0;

    let percent_10kg_more = 0;
    let percent_3kg_more = 0;
    let percent_5kg_more = 0;
    let price_per_1kg = 0;
    let price_per_1km = 0;

    if (vehicle !== "Motorbike" && vehicle !== "Car" && vehicle !== "Van") {
      return;
    }

    if (kilometer > 0 && weight > 0) {
      if (vehicle === "Motorbike") {
        percent_10kg_more = parseFloat(motobikePolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(motobikePolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(motobikePolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(motobikePolicy?.price_per_1kg);
        price_per_1km = parseFloat(motobikePolicy?.price_per_1km);
      } else if (vehicle === "Car") {
        percent_10kg_more = parseFloat(carPolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(carPolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(carPolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(carPolicy?.price_per_1kg);
        price_per_1km = parseFloat(carPolicy?.price_per_1km);
      } else if (vehicle === "Van") {
        percent_10kg_more = parseFloat(vanPolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(vanPolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(vanPolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(vanPolicy?.price_per_1kg);
        price_per_1km = parseFloat(vanPolicy?.price_per_1km);
      }

      if (weight < 3) {
        price = price_per_1km * price_per_1kg * kilometer;
      } else if (weight < 5) {
        price =
          price_per_1km * price_per_1kg * (1 + percent_3kg_more) * kilometer;
      } else if (weight < 10) {
        price =
          price_per_1km * price_per_1kg * (1 + percent_5kg_more) * kilometer;
      } else {
        price =
          price_per_1km * price_per_1kg * (1 + percent_10kg_more) * kilometer;
      }

      setPrice(price.toFixed(2));
    }
  };

  const submitData = (orderId, authorizationId) => {
    setFormError(false);
    setShowSpinner(true);

    let newLocationList = [];
    locationList.map(location => {
      const idx = detailKilometers.findIndex(it => it.destination.id === location.id);
      if(idx > -1) {
        newLocationList.push({  // kkj
          ...location,
          distance: detailKilometers[idx].distance,
          duration: detailKilometers[idx].duration
        })
      } else {
        newLocationList.push({
          ...location,
        })
      }
    })

    const payload = {
      ...formValues,
      location_data: newLocationList,
      status: "inWaitList",
      distance: totalKilometers,
      uid: userid,
      paymentOrderId: orderId,
      paymentAuthorizationId: authorizationId,
      paymentStatus: "AUTHORIZE",
      hasChild: locationList?.length > 2 ? "YES" : "NO", // > 2 ? true : false
      routeStatus: null,
    };
    packageDetailsRef
      .doc(firebase.firestore.Timestamp.now().toMillis().toString())
      .set(payload)
      .then(() => {
        const orderArray = orderData;
        props?.onValueAdd(orderArray);
      })
      .catch(e => {})
      .finally(() => {
        setShowSpinner(false);
        props.onHide();
        setLocationList(defaultLocationList);
        setFormValues(defaultValue);
      });
  };

  const closeModal = () => {
    setFormError(false);
    props.onHide();
  };

  const onSuccess = data => {
    const { orderId, authorizationId } = data;
    setPaypalModalShow(false);
    submitData(orderId, authorizationId);
  };

  const onFail = data => {
    setPaypalModalShow(false);
  };

  const onSaveForContactInfo = location_data => {
    const newList = [...locationList];
    const findIndex = newList.findIndex((it) => it.id === location_data.id);
    if (findIndex > -1) {
      newList[findIndex] = {
        ...location_data,
      }
    }
    setLocationList(newList);
    setShowRouteInfoModal(false)
  };

  const checkValidate = () => {
    const locationDataList = locationList.every(
      item => item.location && item.lat !== null && item.lng !== null
    );
    const distanceError = detailKilometers.every(
      item => item.distance && item.distance !== -1
    );

    return (
      !formValues.date ||
      !formValues.priority ||
      !formValues.to ||
      !formValues.from ||
      !locationDataList ||
      !distanceError ||
      !formValues.vehicle ||
      !formValues.weight ||
      !formValues.price ||
      !formValues.orderid ||
      // !formValues.notes ||
      !formValues.priceYouPay
    );
  };

  const handleSubmit = e => {

    const checkOrderType = name => {
      return locationList.some(obj => obj.orderType === name);
    };

    const checkOrderTypeBoth = () => {
      return locationList.every(
        obj => obj.orderType === "delivery" || obj.orderType === "pickup"
      );
    };

    if (checkValidate()) {
      console.log(formValues);
      setFormError(true);
      setErrorMsg("Please fill in all required fields");
    } else if (!checkOrderType("pickup")) {
      setErrorMsg(true);
      alert("Please add Pick Up on Order Type");
      setErrorMsg("Please add pick up on Order Type");
    } else if (!checkOrderType("delivery")) {
      setErrorMsg(true);
      alert("Please add delivery");
      setErrorMsg("Please add Delivery on Order Type");
    } else if (!checkOrderTypeBoth()) {
      setErrorMsg(true);
      setErrorMsg("Please select  order type");
      alert("Please select order type");
    } else {
      setFormError(false);
      setPaypalModalShow(true);
    }
  };

  const handlePriceChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === "price") {
      let myValue = value * 1.1;

      setYouPay(myValue.toFixed(2));
      setFormValues({
        ...formValues,
        priceYouPay: myValue.toFixed(2),
        [name]: value,
      });
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    console.log('----------value-------', value);
    setFormValues({ ...formValues, [name]: value });
    // PricingCalculation(name, value);
  };

  const handleOptimise = async () => {
    let tempList = [];
    setLoading(true);
    tempList = await optimiseAddress(0, locationList);

    for (let idx = 1; idx < locationList.length - 2; idx++) {
      console.log("-------idx -----------", idx);
      tempList = await optimiseAddress(idx, tempList);
    }

    setLocationList(tempList);
    setLoading(false);
  };

  const optimiseAddress = async (startIndex, originList) => {
    var service = new window.google.maps.DistanceMatrixService();

    if (!originList || originList?.length < startIndex + 2) return originList;
    let kilometersDetail = [];

    // calc the distance between 0th and others
    const element1 = originList[startIndex];
    for (let index = startIndex + 1; index < originList?.length; index++) {
      const element2 = originList[index];
      let destinationPlaces = [];
      let originPlaces = [];

      originPlaces.push(element1.location);
      destinationPlaces.push(element2.location);

      const response = await getDistanceMatrix(service, {
        origins: originPlaces,
        destinations: destinationPlaces,
        travelMode: "DRIVING", //"DRIVING", // BICYCLING, //TRANSIT, // WALKING
      });

      if (!Array.isArray(response?.rows) || response?.rows?.length < 1)
        continue;
      if (
        !Array.isArray(response?.rows[0]?.elements) ||
        response?.rows[0]?.elements?.length < 1
      )
        continue;

      const result = response?.rows[0]?.elements[0];
      if (result.status === "OK") {
        kilometersDetail.push({
          ...element2,
          distance: result?.distance?.value / 1000,
          duration: result?.duration?.text,
        });
      } else {
        // fail
        kilometersDetail.push({
          ...element2,
          distance: -1,
          duration: "",
        });
      }
    }

    kilometersDetail.sort(GetSortOrder("distance"));

    let tempList = [];
    for (let i = 0; i <= startIndex; i++) {
      tempList.push(originList[i]);
    }

    kilometersDetail.map(p => {
      originList.map((q, index) => {
        if (index === 0) return;
        if (p.id === q.id) {
          tempList.push(q);
        }
      });
    });

    return tempList;
  };

  const handleRouteInfoModal = (locationData) => {
    setCurrentLocationData(locationData);
    setShowRouteInfoModal(true);
  }

  // const handleOptimise = () => {
  //   detailKilometers.sort(GetSortOrder("distance"));
  //   const tempList = [];
  //   tempList.push(locationList[0]);

  //   detailKilometers.map( p => {
  //     locationList.map( (q, index) => {
  //       if(index === 0) return;
  //       if(p.location === q.location) {
  //         tempList.push(q);
  //       }
  //     })
  //   })

  //   setLocationList(tempList);
  // };

  useEffect(() => {
    packagePricing
      .doc("motobike")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setMotobikePolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting motobike document:", error);
      });
    packagePricing
      .doc("car")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setCarPolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting car document:", error);
      });
    packagePricing
      .doc("van")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setVanPolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting van document:", error);
      });
  }, []);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="main_form">
            <div className="first_input">
              <div className="order">
                <label>
                  Order Id<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="number"
                  disabled
                  placeholder="Order Id"
                  onChange={handleInputChange}
                  name="orderid"
                  value={formValues.orderid}
                />
              </div>
              <div className="date_section">
                <label>
                  {" "}
                  Select Date<span style={{ color: "red" }}>*</span>:{" "}
                </label>
                <input
                  placeholder="dd/mm/yyyy"
                  type="date"
                  onChange={handleInputChange}
                  name="date"
                  value={formValues.date}
                />
              </div>
            </div>
            <div className="second_input">
              <div className="from_take_section">
                <label>
                  Pick Up Time<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="time"
                  placeholder="From"
                  onChange={handleInputChange}
                  name="from"
                />
              </div>
              <div className="priority_section">
                <label>
                  Priority<span style={{ color: "red" }}>*</span>:
                </label>
                <select
                  name="priority"
                  id="priority"
                  onChange={handleInputChange}
                >
                  <option disabled selected>
                    Select Priority
                  </option>
                  <option value="higher">High</option>
                  <option value="medium">Medium</option>
                  <option value="lower">Low</option>
                </select>
              </div>
            </div>
            <div className="third_input">
              <div className="to_take_section">
                <label>
                  Drop off time<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="time"
                  placeholder="To"
                  onChange={handleInputChange}
                  name="to"
                />
              </div>
              <div className="vehical-select">
                <label>
                  Vehicle<span style={{ color: "red" }}>*</span>:
                </label>
                <select
                  name="vehicle"
                  id="vehicle"
                  onChange={handleInputChange}
                >
                  <option disabled selected>
                    Select Vehicle
                  </option>
                  <option value="Car">Car</option>
                  <option value="Motorbike">Motorbike</option>
                  <option value="Van">Van</option>
                </select>
              </div>
            </div>

            <div className="select-weight">
              <label>
                {"Total Weight"}
                <span style={{ color: "red" }}>*</span>:
              </label>

              <select name="weight" id="weight" onChange={handleInputChange}>
                <option selected disabled hidden>
                  Select weight
                </option>
                <option value="1kg">1kg</option>
                <option value="3kg">3kg</option>
                <option value="5kg">5kg</option>
                <option value="10kg">10kg</option>
                <option value=">10kg"> &gt; 10kg </option>
              </select>
            </div>

            <LocationAutocomplate
              locationList={locationList}
              setLocationList={setLocationList}
              setTotalKilometers={setTotalKilometers}
              setDetailKilometers={setDetailKilometers}
              setShowModal={handleRouteInfoModal}
            />
            <div className="notes_section">
              <label>
                Notes<span style={{ color: "red" }}></span>:
              </label>
              <input
                required
                type="text"
                placeholder="Notes"
                onChange={handleInputChange}
                name="notes"
              />
            </div>
            <div className="second_input">
              <div>
                <div className="kilometer_div">
                  <span className="kilometer">Kilometer: </span>
                  <span
                    className={`km_value ${errorKilometer && `error_color`}`}
                  >
                    {totalKilometers} {showDetailKilometers}
                  </span>
                </div>
              </div>
              <div className="priority_section">
                <label>
                  Auto Price<span style={{ color: "red" }}></span>:
                </label>
                <input
                  disabled="true"
                  type="number"
                  placeholder="Price"
                  name="AutoPrice"
                  value={price}
                />
              </div>
            </div>
            <div className="second_input">
              <div className="from_take_section">
                <label>
                  Price<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="number"
                  placeholder="Price"
                  onChange={handlePriceChange}
                  name="price"
                />
              </div>
              <div className="priority_section">
                <label>
                  You Pay<span style={{ color: "red" }}></span>:
                </label>
                <input
                  disabled="true"
                  type="number"
                  placeholder="Price"
                  name="priceYouPay"
                  value={youPay}
                />
              </div>
            </div>

            {formError && <span style={{ color: "red" }}>{errorMsg}</span>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_buttons_container">
            <Button size="sm" onClick={handleOptimise} className="btn_optimise">
              <div className="mr-2">
                <Spinner
                  style={{
                    display: !loading ? "none" : "",
                    color: "white",
                    fontSize: "10px",
                  }}
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="false"
                  animation="border"
                />
              </div>{" "}
              {!loading ? "Optimise" : ""}
            </Button>

            <div className="modal_buttons">
              <Button
                onClick={handleSubmit}
                className="active d-flex align-items-center"
              >
                <div className="mr-2">
                  <Spinner
                    style={{
                      display: !showSpinner ? "none" : "",
                      color: "white",
                      fontSize: "10px",
                    }}
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="false"
                    animation="border"
                  />
                </div>{" "}
                Save{" "}
              </Button>
              <Button className="close_modal" onClick={closeModal}>
                {" "}
                Close{" "}
              </Button>
            </div>
          </div>
        </Modal.Footer>

        <PaypalModal
          show={paypalModalShow}
          amount={formValues.priceYouPay}
          currency={"ILS"}
          description={"Kiwi user"}
          onHide={() => setPaypalModalShow(false)}
          onSuccess={data => onSuccess(data)}
          onFail={data => onFail(data)}
        />

        <RouteInfoModal 
          data={currentLocationData}
          show={showRouteInfoModal}
          onClose={() => setShowRouteInfoModal(false)}
          onSave={(data) => onSaveForContactInfo(data)}
        />
      </Modal>
    </div>
  );
};

export default AddOrder;
