import React, { Component } from "react";
import AddressInfo from "../Layout/AddressInfo/AddressInfo";
import Feature from "../Layout/Feature/Feature";
import JoinUs from "../Layout/JoinUs/JoinUs";
import Sender from "../Layout/Sender_form/Sender";
import Testimonial from "../Layout/Testimonial/Testimonial";
import ContactUs from "../Layout/ContactUs/ContactUs";

const defaultAddressData = {
  selectedUpToKG: null,
  selectedUpToHours: null,
  pickupAdd: "",
  senderDetails: "",
  dropOffAddress: "",
  receiverName: "",
  selectedLeaveAt: null,
  selectedPackageFitsFor: null,
  price: 0.0,
  isOpenSenderModal: false,
  isOpenReceiverModal: false,
};

const defaultContactUsData = {
  contact_name: '',
  contact_email: '',
  phoneNumber: '',
  message: '',
};

class HomeContainer extends Component {
  state = {
    addressData: defaultAddressData,
    contactUsData: defaultContactUsData,
    activeTab: "Sender",
    senderPopupData: {
      senderName: "",
      senderPhoneNo: "",
      senderEmail: "",
      emailForInvoicing: ""
    },
    receiverPopupData: {
      receiverName: "",
      receiverPhoneNo: "",
    },
    activeIndex: 0,
  };

  handleAddressInfoStateChange = (key, value) => {
    this.setState({
      addressData: {
        ...this.state.addressData,
        [key]: value,
      },
    });
  };

  handleJoinUsContactStateChange = (key, value) => {
    this.setState({
      contactUsData: {
        ...this.state.addressData,
        [key]: value,
      },
    });
  };

  handleChangeTab = (tabName) => {
    this.setState({
      activeTab: tabName,
    });
  };

  handleToggleSenderDetailPopup = () => {
    this.setState({
      isOpenSenderModal: !this.state.isOpenSenderModal
    });
  };

  handleToggleReceiverDetailPopup = () => {
    this.setState({
      isOpenReceiverModal: !this.state.isOpenReceiverModal
    });
  };

  handleSenderPopupStateChange = (key, value) => {
    this.setState({
      senderPopupData: {
        ...this.state.senderPopupData,
        [key]: value,
      },
    });
  };

  handleReceiverPopupStateChange = (key, value) => {
    this.setState({
      receiverPopupData: {
        ...this.state.receiverPopupData,
        [key]: value,
      },
    });
  };

  handleChangeSlide = (selectedIndex, e) => {
    this.setState({
      activeIndex: selectedIndex
    })
  }

  render() {
    const { addressData, contactUsData, isOpenSenderModal, isOpenReceiverModal, senderPopupData, receiverPopupData } = this.state;
    return (
      <div>
        <AddressInfo
          addressData={addressData}
          handleStateChange={this.handleAddressInfoStateChange}
          isOpenSenderModal={isOpenSenderModal}
          isOpenReceiverModal={isOpenReceiverModal}
          handleToggleSenderDetailPopup={this.handleToggleSenderDetailPopup}
          handleToggleReceiverDetailPopup={this.handleToggleReceiverDetailPopup}
          senderPopupData={senderPopupData}
          receiverPopupData={receiverPopupData}
          handleSenderPopupStateChange={this.handleSenderPopupStateChange}
          handleReceiverPopupStateChange={this.handleReceiverPopupStateChange}
        />
        <Feature />
        <JoinUs
          activeTab={this.state.activeTab}
        />
        <Sender
          activeTab={this.state.activeTab}
          handleChangeTab={this.handleChangeTab}
          addressData={addressData}
          handleStateChange={this.handleAddressInfoStateChange}
          isOpenSenderModal={isOpenSenderModal}
          isOpenReceiverModal={isOpenReceiverModal}
          handleToggleSenderDetailPopup={this.handleToggleSenderDetailPopup}
          handleToggleReceiverDetailPopup={this.handleToggleReceiverDetailPopup}
          senderPopupData={senderPopupData}
          receiverPopupData={receiverPopupData}
          handleSenderPopupStateChange={this.handleSenderPopupStateChange}
          handleReceiverPopupStateChange={this.handleReceiverPopupStateChange}
        />
        <Testimonial
          activeIndex={this.state.activeIndex}
          handleChangeSlide={this.handleChangeSlide}
        />
        <ContactUs
          contactUsData={contactUsData}
          handleStateChange={this.handleJoinUsContactStateChange}
        />
      </div>
    );
  }
}

export default HomeContainer;
