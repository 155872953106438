import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Button } from "react-bootstrap";
import { getDistanceMatrix } from "../../../../utils/common";
import Spinner from "react-bootstrap/Spinner";
import { MAX_DELIVERY_ADDRESS } from "../../../../utils/constant";
import RouteInfoModal from './RouteInfoModal';

const LocationAutocomplate = props => {
  const {
    locationList,
    setLocationList,
    setTotalKilometers,
    disabled,
    setDetailKilometers,
    setShowModal
  } = props;
  const [SingleSuggestion, setSingleSuggestion] = useState();
  const destinationPlaces = [];
  const originPlaces = [];

  const handleChange = (address, index) => {
    const tempList = [...locationList];
    let tempObj = tempList[index];
    tempObj = { ...tempObj, location: address, lat: null, lng: null };
    tempList[index] = { ...tempObj };
    setLocationList(tempList);
  };

  const handleSelect = (address, index) => {
    const tempList = [...locationList];
    const tempObj = tempList[index];
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        tempList[index] = { ...tempObj, location: address, ...latLng };
      })
      .catch(error => console.error("Error", error))
      .finally(() => {
        setLocationList(tempList);
      });
  };

  const handleLocationRemove = index => {
    const list = [...locationList];
    list.splice(index, 1);

    setLocationList(list);
  };

  const handleRouteInformationModal = (locationData) => {
    setShowModal(locationData);
  };

  const handleLocationAdd = () => {
    setLocationList([
      ...locationList,
      {
        id: new Date().getTime(),
        location: "",
        lat: null,
        lng: null,
        orderType: "",
        distance: "",
        duration: "",
        contact_info: {
          name: "",
          phone: "",
          notes: "",
        }
      },
    ]);
  };

  const handleSelectType = (e, index) => {
    const list = [...locationList];
    const tempObj = list[index];
    list[index] = { ...tempObj, orderType: e.target.value };
    setLocationList(list);
  };

  useEffect(() => {
    getDistance();

    // function callback(response, status) {
    //   if (!locationList) return;
    //   if (!Array.isArray(response?.rows) || response?.rows?.length < 1) return;

    //   let kilometersDetail = [];
    //   const tempDataList = [...locationList];
    //   let totalKm = 0;

    //   response?.rows[0]?.elements?.map((x, index) => {
    //     // destination array
    //     const destinationIndex = index + 1;
    //     const tempObj = tempDataList[destinationIndex]; // because 0 index is just origin place.. other indexs is destination address.

    //     if (x.status === "OK") {
    //       totalKm += x?.distance?.value / 1000;
    //       kilometersDetail.push({
    //         ...tempObj,
    //         distance: x?.distance?.value / 1000,
    //         duration: x?.duration?.text,
    //       });
    //     } else {
    //       // fail
    //       kilometersDetail.push({
    //         ...tempObj,
    //         distance: -1,
    //         duration: "",
    //       });
    //     }
    //   });
    //   setTotalKilometers(totalKm.toFixed(3));
    //   setDetailKilometers(kilometersDetail);
    // }

    // // set Origin and Destination
    // locationList?.forEach((element, index) => {
    //   if (index === 0) {
    //     originPlaces.push(element.location);
    //   } else {
    //     destinationPlaces.push(element.location);
    //   }
    // });

    // service.getDistanceMatrix(
    //   {
    //     origins: originPlaces,
    //     destinations: destinationPlaces,
    //     travelMode: 'DRIVING', //"DRIVING", // BICYCLING, //TRANSIT, // WALKING
    //   },
    //   callback
    // );
  }, [locationList]);

  const getDistance = async (start, end) => {
    let totalKm = 0;
    let kilometersDetail = [];

    // check locationList
    let isLocationEdit = false;
    locationList?.forEach((element, index) => {
      if (!element.lat || !element.lng) {
        isLocationEdit = true;
      }
    });

    var service = new window.google.maps.DistanceMatrixService();

    if (!locationList || locationList?.length < 2) return;

    for (let index = 0; index < locationList?.length - 1; index++) {
      const element1 = locationList[index];
      const element2 = locationList[index + 1];
      let destinationPlaces2 = [];
      let originPlaces2 = [];

      if (isLocationEdit) {
        kilometersDetail.push({
          origin: element1,
          destination: element2,
          distance: -1,
          duration: "",
        });
        continue;
      }

      originPlaces2.push(element1.location);
      destinationPlaces2.push(element2.location);

      const response = await getDistanceMatrix(service, {
        origins: originPlaces2,
        destinations: destinationPlaces2,
        travelMode: "DRIVING", //"DRIVING", // BICYCLING, //TRANSIT, // WALKING
      });

      if (!Array.isArray(response?.rows) || response?.rows?.length < 1)
        continue;
      if (
        !Array.isArray(response?.rows[0]?.elements) ||
        response?.rows[0]?.elements?.length < 1
      )
        continue;

      const result = response?.rows[0]?.elements[0];
      if (result.status === "OK") {
        totalKm += result?.distance?.value / 1000;
        kilometersDetail.push({
          origin: element1,
          destination: element2,
          distance: result?.distance?.value / 1000,
          duration: result?.duration?.text,
        });
      } else {
        // fail
        kilometersDetail.push({
          origin: element1,
          destination: element2,
          distance: -1,
          duration: "",
        });
      }
    }

    setTotalKilometers(totalKm.toFixed(3));
    setDetailKilometers(kilometersDetail);
  };

  return (
    <div>
      <div className="input_label_section">
        <p>
          Order Type<span style={{ color: "red" }}>*</span>:
        </p>
        <p>
          Location<span style={{ color: "red" }}>*</span>:
        </p>
      </div>
      <div className="location_section">
        <div className="locatin_fields">
          {locationList?.map((obj, index) => (
            <div
              key={index}
              className="input_icons_section location_input_section"
            >
              <select
                className="location_section select"
                value={obj.orderType}
                onChange={e => handleSelectType(e, index)}
                name="ordertype"
                id="ordertype"
              >
                <option>Select Order Type</option>
                <option value="delivery">Delivery</option>
                <option value="pickup">Pick Up</option>
              </select>
              <PlacesAutocomplete
                value={obj.location}
                onChange={event => {
                  handleChange(event, index);
                }}
                onSelect={e => handleSelect(e, index)}
                highlightFirstSuggestion={true}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="location-search-input">
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: `location-search-input select_input ${
                          obj.lat === null && `error_color`
                        }`,
                        disabled: disabled ? true : false,
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        if (index === 0) {
                          setSingleSuggestion(suggestion);
                        }
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <div className="items add_items">
                <div className="items items_plus">
                  {locationList.length - 1 === index &&
                  locationList.length < MAX_DELIVERY_ADDRESS ? (
                    <i
                      onClick={handleLocationAdd}
                      className="fa-solid fa-plus"
                    ></i>
                  ) : (
                    <div style={{ width: "26px", height: "26px" }}> </div>
                  )}
                </div>
                <div className="item_xmark">
                  {locationList.length !== 2 && locationList.length > 2 && (
                    <i
                      onClick={() => handleLocationRemove(index)}
                      className="fa-solid fa-xmark"
                    ></i>
                  )}
                </div>
                <div className="item_xmark">
                    <i
                      onClick={() => handleRouteInformationModal(obj)}
                      className="fa-solid fa-info"
                    ></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocationAutocomplate;
