import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DashboardHeader from "../Header/Layout/DashboardHeader/DashboardHeader";
import DashboardSidePanel from "../Header/Layout/DashboardSidePanel/DashboardSidePanel";
import "../../Components/Pages/Dashboard/Layout/Dashboard.css";
import { DashboardContainer } from "../Pages/Dashboard/container/DashboardContainer";

// toast.configure()

const AppLayout = () => {
  const [isActive, setIsActive] = useState(false);
  const handleDrawer = () => {
    setIsActive(!isActive);
  };
  return (
    <div>
      <DashboardSidePanel isActive={isActive} />
      <div
        className="dashboard_middle_component"
        style={{
          padding: isActive ? "58px 0 0 0" : "58px 0 0 260px",
        }}
      >
        <Route path="/app/dashboard" exact component={DashboardContainer} />
        <Redirect from="/app" to="/app/dashboard" />
      </div>
      <DashboardHeader handleDrawer={handleDrawer} isActive={isActive} />

      <ToastContainer />
    </div>
  );
};

export default AppLayout;
