import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { db, firebaseFunc } from "../../../../Config/firebase";
import LocationAutocomplate from "./LocationAutocomplate";
import { GetSortOrder, getDistanceMatrix } from '../../../../utils/common';
import RouteInfoModal from './RouteInfoModal';

const EditOrderData = props => {
  const { rowData, editRowID, getOrderList } = props;
  const [prevData, setPrevData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [locationList, setLocationList] = useState([
    {
      id: new Date().getTime(),
      location: "",
      lat: null,
      lng: null,
      orderType: "",
      distance: "",
      duration: "",
    },
    {
      id: new Date().getTime() + 1,
      location: "",
      lat: null,
      lng: null,
      orderType: "",
      distance: "",
      duration: "",
    },
  ]);
  const [formValues, setFormValues] = useState(prevData);
  const [formError, setFormError] = useState(false);
  const packageDetailsRef = db.collection("packageDetails");
  const [totalKilometers, setTotalKilometers] = useState(0);
  const [detailKilometers, setDetailKilometers] = useState([]);

  const packagePricing = db.collection("packagePricing");
  const [motobikePolicy, setMotobikePolicy] = useState({});
  const [carPolicy, setCarPolicy] = useState({});
  const [vanPolicy, setVanPolicy] = useState({});

  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const [showRouteInfoModal, setShowRouteInfoModal] = useState(false);
  const [currentLocationData, setCurrentLocationData] = useState(null);

  let showDetailKilometers = "";
  let errorKilometer = false;
  detailKilometers.map((p, index) => {
    if (index === 0) showDetailKilometers = "( ";
    showDetailKilometers += (index > 0 ? ", " : "") + p.distance;
    if (index === detailKilometers.length - 1) showDetailKilometers += " )";
    if (!p.distance || p.distance === -1) errorKilometer = true;
  });

  useEffect(() => {
    PricingCalculation(formValues.vehicle, formValues.weight, totalKilometers);
  }, [formValues.vehicle, formValues.weight, totalKilometers]);

  const PricingCalculation = (vehicle, kg, km) => {
    const weight = parseFloat(kg === ">10kg" ? 100 : kg);
    const kilometer = parseFloat(km);
    let price = 0;

    let percent_10kg_more = 0;
    let percent_3kg_more = 0;
    let percent_5kg_more = 0;
    let price_per_1kg = 0;
    let price_per_1km = 0;

    if (vehicle !== "Motorbike" && vehicle !== "Car" && vehicle !== "Van") {
      return;
    }

    if (kilometer > 0 && weight > 0) {
      if (vehicle === "Motorbike") {
        percent_10kg_more = parseFloat(motobikePolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(motobikePolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(motobikePolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(motobikePolicy?.price_per_1kg);
        price_per_1km = parseFloat(motobikePolicy?.price_per_1km);
      } else if (vehicle === "Car") {
        percent_10kg_more = parseFloat(carPolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(carPolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(carPolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(carPolicy?.price_per_1kg);
        price_per_1km = parseFloat(carPolicy?.price_per_1km);
      } else if (vehicle === "Van") {
        percent_10kg_more = parseFloat(vanPolicy?.percent_10kg_more);
        percent_3kg_more = parseFloat(vanPolicy?.percent_3kg_more);
        percent_5kg_more = parseFloat(vanPolicy?.percent_5kg_more);
        price_per_1kg = parseFloat(vanPolicy?.price_per_1kg);
        price_per_1km = parseFloat(vanPolicy?.price_per_1km);
      }

      if (weight < 3) {
        price = price_per_1km * price_per_1kg * kilometer;
      } else if (weight < 5) {
        price =
          price_per_1km * price_per_1kg * (1 + percent_3kg_more) * kilometer;
      } else if (weight < 10) {
        price =
          price_per_1km * price_per_1kg * (1 + percent_5kg_more) * kilometer;
      } else {
        price =
          price_per_1km * price_per_1kg * (1 + percent_10kg_more) * kilometer;
      }

      setPrice(price.toFixed(2));
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClose = () => {
    setFormError(false);
    props.onHide();
  };

  const submitData = async () => {
    setFormError(false);
    packageDetailsRef
      .doc(formValues.id)
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        if (tempList.paymentStatus === "COMPLETED") {
          console.log("-----Payment is already captured!-----");
          toast("Payment is already captured!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          getOrderList();
          props.onHide();
        } else {
          const payload = {
            ...formValues,
            location_data: locationList,
            status: "inWaitList",
            distance: totalKilometers,
          };
          delete payload.id;
          packageDetailsRef
            .doc(formValues.id)
            .set(payload)
            .then(() => {
              getOrderList();
              props.onHide();
            })
            .catch(e => {})
            .finally(() => {});
        }
      });
  };

  const handleRouteInfoModal = (locationData) => {
    setCurrentLocationData(locationData);
    setShowRouteInfoModal(true);
  }

  const onSaveForContactInfo = location_data => {
    const newList = [...locationList];
    const findIndex = newList.findIndex((it) => it.id === location_data.id);
    if (findIndex > -1) {
      newList[findIndex] = {
        ...location_data,
      }
    }
    setLocationList(newList);
    setShowRouteInfoModal(false);
  };

  const optimiseAddress = async (startIndex, originList) => {
    var service = new window.google.maps.DistanceMatrixService();

    if (!originList || originList?.length < startIndex + 2) return originList;
    let kilometersDetail = [];

    // calc the distance between 0th and others
    const element1 = originList[startIndex];
    for (let index = startIndex + 1; index < originList?.length; index++) {
      const element2 = originList[index];
      let destinationPlaces = [];
      let originPlaces = [];

      originPlaces.push(element1.location);
      destinationPlaces.push(element2.location);

      const response = await getDistanceMatrix(service, {
        origins: originPlaces,
        destinations: destinationPlaces,
        travelMode: "DRIVING", //"DRIVING", // BICYCLING, //TRANSIT, // WALKING
      });

      if (!Array.isArray(response?.rows) || response?.rows?.length < 1)
        continue;
      if (
        !Array.isArray(response?.rows[0]?.elements) ||
        response?.rows[0]?.elements?.length < 1
      )
        continue;

      const result = response?.rows[0]?.elements[0];
      if (result.status === "OK") {
        kilometersDetail.push({
          ...element2,
          distance: result?.distance?.value / 1000,
          duration: result?.duration?.text,
        });
      } else {
        // fail
        kilometersDetail.push({
          ...element2,
          distance: -1,
          duration: "",
        });
      }
    }

    kilometersDetail.sort(GetSortOrder("distance"));

    let tempList = [];
    for (let i = 0; i <= startIndex; i++) {
      tempList.push(originList[i]);
    }

    kilometersDetail.map(p => {
      originList.map((q, index) => {
        if (index === 0) return;
        if (p.id === q.id) {
          tempList.push(q);
        }
      });
    });

    return tempList;
  };

  const handleOptimise = async () => {
    let tempList = [];
    setLoading(true);
    // tempList = await optimiseAddress(0, locationList);
    // tempList = await optimiseAddress(1, tempList);

    setLocationList(tempList);
    setLoading(false);
  };

  const handleUpdate = () => {
    const locationDataList = locationList.every(
      item => item.location && item.lat !== null && item.lng !== null
    );
    const distanceError = detailKilometers.every(
      item => item.distance && item.distance !== -1
    );
    const userExists = name => {
      return locationList.some(obj => {
        return obj.orderType === name;
      });
    };
    if (
      !formValues.date ||
      !formValues.priority ||
      !formValues.to ||
      !formValues.from ||
      !locationDataList
    ) {
      setFormError(true);
      setErrorMsg("Fill the required fields");
    } else if (!userExists("delivery") && !userExists("pickup")) {
      setErrorMsg(true);
      setErrorMsg("Please select  order type");
    } else if (!userExists("delivery")) {
      setErrorMsg(true);
      alert("Please add delivery location");
      setErrorMsg("Please add delivery location.");
    } else if (!userExists("pickup")) {
      setErrorMsg(true);
      alert("Please add pick up location");
      setErrorMsg("Please add pick up location.");
    } else {
      setFormError(false);
      submitData();
    }
  };

  useEffect(() => {
    setFormValues(rowData);
    packageDetailsRef
      .doc(rowData.id)
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setLocationList(tempList?.location_data);
      });

    packagePricing
      .doc("motobike")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setMotobikePolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting motobike document:", error);
      });
    packagePricing
      .doc("car")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setCarPolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting car document:", error);
      });
    packagePricing
      .doc("van")
      .get()
      .then(snapshot => {
        let tempList = snapshot?.data();
        setVanPolicy(tempList);
      })
      .catch(error => {
        console.log("Error getting van document:", error);
      });
  }, [editRowID]);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Data
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="main_form">
            <div className="first_input">
              <div className="order">
                <label>
                  Order Id<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="number"
                  disabled
                  value={formValues.orderid}
                  placeholder="Order Id"
                  onChange={handleInputChange}
                  name="orderid"
                />
              </div>
              <div className="date_section">
                <label>
                  Select Date<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  placeholder="dd/mm/yyyy"
                  type="date"
                  defaultValue={formValues.date}
                  onChange={handleInputChange}
                  name="date"
                />
              </div>
            </div>
            <div className="second_input">
              <div className="from_take_section">
                <label>
                  From<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="time"
                  placeholder="From"
                  value={formValues.from}
                  onChange={handleInputChange}
                  name="from"
                />
              </div>
              <div className="priority_section">
                <label>
                  Priority<span style={{ color: "red" }}>*</span>:
                </label>
                <select
                  value={formValues.priority}
                  name="priority"
                  id="priority"
                  onChange={handleInputChange}
                >
                  <option>Select Priority</option>
                  <option value="lower">Lower</option>
                  <option value="medium">Medium</option>
                  <option value="higher">Higher</option>
                </select>
              </div>
            </div>

            <div className="third_input">
              <div className="to_take_section">
                <label>
                  To<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  type="time"
                  placeholder="To"
                  defaultValue={formValues.to}
                  onChange={handleInputChange}
                  name="to"
                />
              </div>
              <div className="vehical-select">
                <label>
                  Vehicle<span style={{ color: "red" }}>*</span>:
                </label>
                <select
                  name="vehicle"
                  id="vehicle"
                  onChange={handleInputChange}
                  value={formValues?.vehicle}
                >
                  <option disabled selected hidden>
                    Select Vehicle
                  </option>
                  <option value="Car">Car</option>
                  <option value="Motorbike">Motorbike</option>
                  <option value="Van">Van</option>
                </select>
              </div>
            </div>

            <div className="vehical-select">
              <label>
                Weight<span style={{ color: "red" }}>*</span>:
              </label>
              <select
                name="weight"
                id="weight"
                onChange={handleInputChange}
                value={formValues?.weight}
              >
                <option selected disabled hidden>
                  Select weight
                </option>
                <option value="1kg">1kg</option>
                <option value="3kg">3kg</option>
                <option value="5kg">5kg</option>
                <option value="10kg">10kg</option>
                <option value=">10kg"> &gt;10kg </option>
              </select>
            </div>

            <LocationAutocomplate
              locationList={locationList}
              setLocationList={setLocationList}
              setTotalKilometers={setTotalKilometers}
              setDetailKilometers={setDetailKilometers}
              setShowModal={handleRouteInfoModal}
            />

            <div className="notes_section">
              <label>
                Notes<span style={{ color: "red" }}>*</span>:
              </label>
              <input
                required
                name="notes"
                type="text"
                placeholder="Notes"
                value={formValues.notes}
                onChange={handleInputChange}
              />
            </div>
            <div className="second_input">
              <div>
                <div className="kilometer_div">
                  <label className="kilometer">Kilometer: </label>
                  <span
                    className={`km_value ${errorKilometer && `error_color`}`}
                  >
                    {totalKilometers} {showDetailKilometers}
                  </span>
                </div>
              </div>
              <div className="priority_section">
                <label>
                  Auto Price<span style={{ color: "red" }}></span>:
                </label>
                <input
                  disabled="true"
                  type="number"
                  placeholder="Price"
                  name="AutoPrice"
                  value={price}
                />
              </div>
            </div>
            <div className="second_input">
              <div className="from_take_section">
                <label>
                  Price<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  required
                  disabled="true"
                  type="number"
                  placeholder="Price"
                  onChange={handleInputChange}
                  name="price"
                  value={formValues.price}
                />
              </div>
              <div className="priority_section">
                <label>
                  You Pay<span style={{ color: "red" }}>*</span>:
                </label>
                <input
                  disabled="true"
                  type="number"
                  placeholder="Price"
                  onChange={handleInputChange}
                  name="priceYouPay"
                  value={formValues.priceYouPay}
                />
              </div>
            </div>
            {formError && (
              <span style={{ color: "red" }}>Fill the required fields.</span>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_buttons_container">
            <Button size="sm" onClick={handleOptimise} className="btn_optimise">
              <div className="mr-2">
                <Spinner
                  style={{
                    display: !loading ? "none" : "",
                    color: "white",
                    fontSize: "10px",
                  }}
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="false"
                  animation="border"
                />
              </div>{" "}
              {!loading ? "Optimise" : ""}
            </Button>

            <div className="modal_buttons">
              <Button
                onClick={handleUpdate}
                className="active d-flex align-items-center"
              >
                Update
              </Button>
              <Button onClick={handleClose} className="close_modal">
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer>

        <RouteInfoModal
          data={currentLocationData}
          show={showRouteInfoModal}
          onClose={() => setShowRouteInfoModal(false)}
          onSave={(data) => onSaveForContactInfo(data)}
        />
      </Modal>
    </div>
  );
};

export default EditOrderData;
