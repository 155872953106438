import React from 'react';
import WhiteLogo from '../../../../src/Assets/Images/white full logo@2x 1 (1).png';
import share from '../../../../src/Assets/icon/share-white.png';
import "./Footer.css"
const Footer = () => {
    return (
        <div className="footer text-center text-lg-left">
            <div className="container h-100">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-sm-12 col-lg-6">
                        <img src={WhiteLogo} alt="logo" />
                    </div>
                    <div className="col-sm-12 col-lg-3">
                        <div className="d-flex flex-column mb-5 mb-lg-0">
                            <span className="footer-header">Contact Us</span>
                            <span className="footer-subHeader">03 555 5555</span>
                            <span className="footer-subHeader">iwi@kiwi.com</span>
                            <span className="footer-subHeader">Example Street 78.,<br />City, Country</span>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-3">
                        <div className="d-flex flex-column mb-5 mb-lg-0">
                            <span className="footer-header">Download</span>
                            <span className="footer-subHeader">App for Android</span>
                            <span className="footer-subHeader">App for iPhone</span>
                            <span className="footer-subHeader d-none d-sm-block"><img src={share} alt="" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;