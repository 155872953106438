import React from "react";
import { Modal, Button, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
const LocationList = (props) => {
  const { locationData, spinnerLocationList, setSpinnerLocatinList, setOpenLocation } = props;
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Order
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {spinnerLocationList ?
            <div style={{ textAlign: "center" }} >
              <Spinner
                style={{ color: "black", fontSize: "10px", }}
                as="span"
                role="status"
                aria-hidden="false"
                animation="border"
              /></div>
            :
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Order Type</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {locationData?.map((obj) => {
                  return (
                    <tr>
                      <td>{obj.orderType}</td>
                      <td>{obj.location}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>}
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_buttons">
            <Button
              className="close_modal"
              onClick={() => {
                setOpenLocation(false);
                setTimeout(() => {
                  setSpinnerLocatinList(true)
                }, 500);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default LocationList;
