import React from 'react';
import './Testimonial.css';
import Carousel from 'react-bootstrap/Carousel'
import User1 from "../../../../../Assets/Images/user-1.png";

const Testimonial = (props) => {
    const { activeIndex, handleChangeSlide } = props;
    return (
        <div>
            <div className="testimonal-section d-flex align-items-center">
                <div className="overlay-bg" />
                <div className="container h-100">
                    <div className="row d-none d-sm-flex">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="d-flex align-items-start justify-content-center justify-content-sm-start">
                                <div className="d-flex mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <div>
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-sm-12 col-lg-4 col-md-3">
                            <div className="d-flex align-items-start justify-content-center justify-content-sm-start">
                                <div className="d-flex mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <div>
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-sm-12 col-lg-4 col-md-6">
                            <div className="d-flex align-items-start justify-content-center justify-content-sm-start">
                                <div className="d-flex mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <div>
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex d-sm-none justify-content-center">
                        <Carousel activeIndex={activeIndex} controls={false} onSelect={handleChangeSlide}>
                            <Carousel.Item className={activeIndex === 0 ? 'd-flex' : "d-none"}>
                                <div className="mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <Carousel.Caption className="">
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className={activeIndex === 1 ? 'd-flex' : "d-none"}>
                                <div className="mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <Carousel.Caption className="">
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item className={activeIndex === 2 ? 'd-flex' : "d-none"}>
                                <div className="mr-1">
                                    <img src={User1} alt="user-1" />
                                </div>
                                <Carousel.Caption className="">
                                    <div className="testimonal-header">
                                        “Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Curabitur auctor gravida sagittis. Quisque et ornare mi, in
                                        vehicula sem.”
                                    </div>
                                    <div className="testimonal-subHeader">
                                        John Doe, Position, Company
                                    </div>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;