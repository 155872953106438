import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { db, firebaseFunc, auth } from "../../../../Config/firebase";
import { Button } from "react-bootstrap";
import AddOrder from "./AddOrder";
import "./DataTable.css";
import Spinner from "react-bootstrap/Spinner";
import EditOrderData from "./EditOrderData";
import LocationList from "./LocationList";
import overlayFactory from "react-bootstrap-table2-overlay";

function DataTable() {
  const [editRowID, setEditRowId] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openLocation, setOpenLocation] = useState(false);
  const [locationData, setLocaitonData] = useState([]);
  const [spinnerLocationList, setSpinnerLocatinList] = useState(true);
  const packageDetailsRef = db.collection("packageDetails");
  const [nonSelectableRow, setNonSelectTableRow] = useState([]);

  let lastVisible = 0;

  async function getOrderList() {
    const orderList = [];
    const nonSeletableRowIds = [];
    const uid = localStorage.getItem("kiwi-uid");
    var query = await packageDetailsRef.where("uid", "==", uid);

    query
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach((doc, index) => {
          const temp = doc.data();
          if (temp.status !== "inWaitList") {
            console.log("inside a if", temp.status, doc.id);
            nonSeletableRowIds.push(doc.id);
          }
          temp["location_data"] = temp["location_data"][0].location;
          orderList.push({ id: doc.id, ...temp });
        });
        setOrderData(orderList);
        setNonSelectTableRow(nonSeletableRowIds);
      })
      .catch(e => {
        console.log("get erros", e);
      })
      .finally(() => {});
  }

  async function filterOrderList() {
    const orderList = [];
    const nonSeletableRowIds = [];
    const uid = localStorage.getItem("kiwi-uid");
    var query = await packageDetailsRef.where("uid", "==", uid);

    query
      .orderBy("orderid")
      .startAfter(lastVisible || 0)
      .limit(2)
      .get()
      .then(querySnapshot => {
        lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
        console.log("last", lastVisible);

        querySnapshot.forEach((doc, index) => {
          const temp = doc.data();
          if (temp.status !== "inWaitList") {
            console.log("inside a if", temp.status, doc.id);
            nonSeletableRowIds.push(doc.id);
          }
          temp["location_data"] = temp["location_data"][0].location;
          orderList.push({ id: doc.id, ...temp });
        });
        setOrderData(orderList);
        setNonSelectTableRow(nonSeletableRowIds);
      })
      .catch(e => {
        console.log("get erros", e);
      })
      .finally(() => {});
  }

  async function filterNextOrderList() {
    const orderList = [];
    const nonSeletableRowIds = [];
    const uid = localStorage.getItem("kiwi-uid");
    var query = await packageDetailsRef.where("uid", "==", uid);

    query
      .orderBy("orderid")
      .startAfter(lastVisible || 0)
      .limit(2)
      .get()
      .then(querySnapshot => {
        var lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
        console.log("last", lastVisible);

        querySnapshot.forEach((doc, index) => {
          const temp = doc.data();
          if (temp.status !== "inWaitList") {
            console.log("inside a if", temp.status, doc.id);
            nonSeletableRowIds.push(doc.id);
          }
          temp["location_data"] = temp["location_data"][0].location;
          orderList.push({ id: doc.id, ...temp });
        });
        setOrderData([...orderData, ...orderList]);
        setNonSelectTableRow([...nonSelectableRow, ...nonSeletableRowIds]);
      })
      .catch(e => {
        console.log("get erros", e);
      })
      .finally(() => {});
  }

  const callFirebaseFunction = async paymentAuthorizationId => {
    console.log("paymentAuthorizationId------", paymentAuthorizationId);
    let cancelPaymentByOnCall = firebaseFunc.httpsCallable(
      "cancelPaymentByOnCall"
    );
    try {
      const response = await cancelPaymentByOnCall({
        authorizationId: paymentAuthorizationId,
      });
      if (response?.data?.code === 200) {
        console.log("cancelPaymentByOnCall------ success--", response?.data);
        return true;
      } else {
        console.log("cancelPaymentByOnCall------ fail 1--", response?.data);
        return false;
      }
    } catch (e) {
      console.log("cancelPaymentByOnCall------ fail 2--", e);
      return false;
    }
  };

  const deleteOrderList = async () => {
    setShowSpinner(true);
    for (const row of selectedRowsId) {
      try {
        if(await callFirebaseFunction(row.paymentAuthorizationId)) {
          await packageDetailsRef.doc(row.id).delete();
        } else {
          console.log('-----Payment is already captured!-----');
          toast("Payment is already captured!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Error removing document: ", error);
      }
    }

    setShowSpinner(false);
    getOrderList();
  };

  const hadleEditData = row => {
    setEditRowId(row.id);
    setRowData(row);
    setEditModalShow(true);
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectable: nonSelectableRow,
    selectColumnStyle: (cell, row, rowIndex, formatExtraData) =>
      console.log("disabled", row),
    // style: (row, rowIndex) => { console.log('row', row) },
    style: { background: "rgb(237 231 231 / 32%)" },
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log("row====>", row);
      const tempSelectRowArray = selectedRowsId;
      if (isSelect) {
        tempSelectRowArray.push(row);
      } else if (!isSelect) {
        var cIndex = tempSelectRowArray.findIndex(p => p.id === row.id);
        if (cIndex > -1) {
          tempSelectRowArray.splice(cIndex, 1);
        }
      }
      setSelectedRowsId(tempSelectRowArray);
    },

    onSelectAll: (isSelect, rows, e) => {
      const tempSelectRowArray = selectedRowsId;
      if (isSelect) {
        rows.forEach(obj => {
          tempSelectRowArray.push(obj);
        });
      } else if (!isSelect) {
        tempSelectRowArray.splice(0, tempSelectRowArray.length);
      }
      setSelectedRowsId(tempSelectRowArray);
    },
  };

  console.log("nonSelectableRow", nonSelectableRow);
  const showLocation = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button
          className="editbutton"
          onClick={() => {
            setOpenLocation(true);
            packageDetailsRef
              .doc(row.id)
              .get()
              .then(snapshot => {
                let tempList = snapshot?.data();
                setLocaitonData(tempList?.location_data);
                setSpinnerLocatinList(false);
              });
          }}
        >
          open
        </button>
      </>
    );
  };

  const editData = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button
          className="editbutton"
          onClick={() => {
            hadleEditData(row);
          }}
          style={{
            background: row.status === "inWaitList" ? "" : "#8080807a",
            pointerEvents: row.status === "inWaitList" ? "" : "none",
          }}
        >
          Edit
        </button>
      </>
    );
  };

  const showStatus = (cell, row, rowIndex, formatExtraData) => {
    if (row.status === "inWaitList") {
      return <div style={{ color: "red" }}>In Wait List</div>;
    } else if (row.status === "awaitingPickup") {
      return <div style={{ color: "#0BDC00" }}>Awating Pickup</div>;
    } else if (row.status === "packagePickedup") {
      return <div style={{ color: "#cc00c5" }}>Picked Up</div>;
    } else if (row.status === "packageDeliveryOnRoute") {
      return <div style={{ color: "#01638a" }}>On Route</div>;
    } else {
      return <div style={{ color: "#0022ff" }}>{row.status}</div>;
    }
  };

  const showPaymentStatus = (cell, row, rowIndex, formatExtraData) => {
    if (row.paymentStatus === "AUTHORIZE") {
      return <div style={{ color: "black" }}>Authorize</div>;
    } else if (row.paymentStatus === "COMPLETED") {
      return <div style={{ color: "#0022ff", fontWeight: "bold" }}>Paid</div>;
    }
  };

  const columns = [
    {
      dataField: "orderid",
      text: "Order Id",
      sort: true,
      // sortFunc: (a, b, order, dataField, rowA, rowB) => {
      //   if (order === 'asc') {
      //     return b - a;
      //   }
      //   return a - b; // desc
      // },
      // headerStyle: () => {
      //   return { width: "10%" };
      // }
    },
    // {
    //   dataField: "priority",
    //   text: "priority",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "7%" };
    //   }
    // },
    {
      dataField: "date",
      text: "Select Date",
      sort: true,
      // headerStyle: () => {
      //   return { width: "9%" };
      // }
    },
    {
      dataField: "from",
      text: "From",
      sort: true,
      headerStyle: () => {
        return { width: "6%" };
      }
    },
    {
      dataField: "to",
      text: "To",
      sort: true,
      headerStyle: () => {
        return { width: "6%" };
      }
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      // headerStyle: () => {
      //   return { width: "15%" };
      // }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: showStatus,
      // headerStyle: () => {
      //   return { width: "9%" };
      // }
    },
    {
      dataField: "price",
      text: "Price(₪)",
      sort: true,
      headerStyle: () => {
        return { width: "7%" };
      }
    },
    {
      dataField: "paymentStatus",
      text: "Pay status",
      formatter: showPaymentStatus,
      sort: true,
      // headerStyle: () => {
      //   return { width: "9%" };
      // }
    },
    {
      dataField: "location",
      text: "Location",
      formatter: showLocation,
      sort: false,
    },
    {
      dataField: "Edit",
      text: "Edit",
      formatter: editData,
      sort: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    pageStartIndex: 1,
    // sizePerPage: 5,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
    ],
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
    loading: true,
  });

  console.log("selectRow.nonSelectable", selectRow.nonSelectable);

  useEffect(() => {
    getOrderList();
  }, [orderData.length]);

  // useEffect(() => {
  //   getOrderList();

  //   // filterOrderList();

  //   // setTimeout(() => {
  //   //   filterNextOrderList();
  //   // }, 10000)
  // }, []);

  const onValueAdd = data => {
    getOrderList();
  };
  // const rowStyle = (row) => {
  //   console.log("Hello world", row)
  //   return {
  //     PointerEvents: row.status === "inWaitList" ? '' : 'none'
  //   }
  // }

  const onTableChange = (type, newState) => {
    console.log('type--------', type);
    console.log('newState----', newState);
  };

  const { SearchBar } = Search;
  return (
    <div className="App">
      <ToolkitProvider
        bootstrap4
        data={orderData}
        columns={columns}
        keyField="id"
        search
      >
        {props => (
          <div>
            {console.log("props", props)}
            <div className="data_table_main">
              <div className="upper_search_component">
                <div className="search_component">
                  <SearchBar
                    placeholder="Search"
                    outline="warm-gray-900"
                    {...props.searchProps}
                  />
                </div>
                <div className="clear_btn">
                  <i
                    onClick={() => props.searchProps.onClear()}
                    className="fa-solid fa-xmark"
                  />
                </div>
              </div>
              <div className="tab_section">
                <button className="delet_button" onClick={deleteOrderList}>
                  <div>
                    <Spinner
                      style={{
                        display: showSpinner ? "" : "none",
                        color: "black",
                        fontSize: "10px",
                      }}
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="false"
                      animation="border"
                    />
                  </div>
                  Delete
                </button>
                <Button
                  className="active"
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  <i
                    className="fa-solid fa-plus"
                    style={{ color: "white" }}
                  ></i>{" "}
                  Add Order
                </Button>
              </div>
            </div>

            <BootstrapTable
              keyField="id"
              selectRow={selectRow}
              defaultSorted={defaultSorted}
              pagination={pagination}
              loading={true}
              remote={{
                filter: false,
                pagination: false,
                sort: false,
                cellEdit: false,
              }}
              overlayFactory={overlayFactory({
                spinner: true,
                background: "rgba(192,192,192,0.3)",
              })}
              {...props.baseProps}
              onTableChange={onTableChange}
              striped
              // condensed
            />
            {false && <Spinner animation="border" role="status"></Spinner>}
          </div>
        )}
      </ToolkitProvider>

      {/* ADD ORDER MODAL */}
      <AddOrder
        show={modalShow}
        orderData={orderData}
        onHide={() => setModalShow(false)}
        onValueAdd={data => onValueAdd(data)}
      />

      {/* EDIT ORDER DATA MODAL */}
      <EditOrderData
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        rowData={rowData}
        editRowID={editRowID}
        getOrderList={getOrderList}
      />

      {/* LOCATIONLIST MODAL */}
      <LocationList
        show={openLocation}
        setOpenLocation={setOpenLocation}
        locationData={locationData}
        onHide={() => setEditModalShow(false)}
        setSpinnerLocatinList={setSpinnerLocatinList}
        spinnerLocationList={spinnerLocationList}
      />
    </div>
  );
}

export default DataTable;
