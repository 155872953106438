import React from 'react'
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem("kiwi-uid")
    return (
        <>
            <Route {...rest} render={(props) => {
                return (
                    isAuthenticated ?
                        <Component {...props} />
                        :
                        <Redirect to={{
                            pathname: '/signin',
                            state: { from: props?.location }
                        }} />
                )
            }} />
        </>
    )
}
export default AuthenticatedRoute

