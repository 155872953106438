import React from "react";
import { Modal } from "react-bootstrap";
import "./TrackYourOrder.css";
import AppStore from "../../../../Assets/Images/appstore.svg";
import GooglePlay from "../../../../Assets/Images/googleplay.svg";

const TrackYourOrder = (props) => {
  return (
    <div>
      <Modal
        show={props.isOpenModal}
        onHide={() => props.handleCloseModal("isOpenTrackOrderModal")}
        backdrop="static"
        keyboard={false}
        centered
        className="kiwi-modal"
      >
        <Modal.Header closeButton/>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <div className="trackOrder-header text-sm-center text-left">
            Do you want to track your order?
          </div>
          <div className="trackOrder-subheader text-sm-center text-left">
            To track your order download the app now!
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <div className="store-btn mr-0 mb-4 mb-sm-0 mr-sm-4">
              <img src={AppStore} alt="appstore icon" />
            </div>
            <div className="store-btn">
              <img src={GooglePlay} alt="googleplay icon" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TrackYourOrder;
