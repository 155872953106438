import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "./SignInForm.css";
import HeaderContainer from "../../../Header/Container/HeaderContainer";
import Footer from "../../../Footer/Layout/Footer";

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [forgotError, setForgotError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = e => {
    setEmail(e.target.value);
  };

  const validate = email => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  function handleSubmit() {
    setIsSubmitting(true);
    setFormErrors(validate(email));
  }

  const resetPassword = () => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        alert("Check your inbox for further instructions");
        history.push("/auth/signin");
      })
      .catch(function (error) {
        var errorCode = error.code;
        setForgotError(errorCode);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      resetPassword();
    }
  }, [formErrors]);

  return (
    <>
      <HeaderContainer />
      <div className="body">
        <div className="from_section">
          <div className="Para_firts_text">
            <p>Forgot Password ?</p>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="email"
              onChange={handleChange}
              value={email}
              placeholder="Enter your email"
            />
            {formErrors?.email && (
              <span className="error">{formErrors?.email}</span>
            )}
          </div>

          <div className="button-container">
            <button onClick={handleSubmit}>Send Email</button>
          </div>
          <div className="login-error">
            {forgotError && <span>{forgotError}</span>}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ForgotPassword;
