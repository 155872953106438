import React from 'react'
import './DashboardSidePanel.css'
import logo from '../../../../Assets/Images/Logo.png'
import Profile from '../../../../Assets/Images/profile_img_logo.jpg'
const DashboardSidePanel = (props) => {
    const userData = JSON.parse(localStorage.getItem('userInfo'))
    return (
        <div className={props.isActive ? "side_panel" : ""}>
            <div className='main_left_slider'>
                <div className="logo_section">
                    <div className='brand_logo'>
                        <img src={logo} alt="fasffs" />
                    </div>
                </div>
                <div className='user_details'>
                    <div className='user_img'>
                        <div>
                            <img className='sidepanel_profile_img' src={Profile}></img>
                        </div>
                    </div>
                    <div className='user_name'>
                        <h3>{userData?.displayName}</h3>
                    </div>
                </div>
                <div className='dashboard_menus'>
                    <div className='menu_icons'>
                        <p>Dashboard</p>
                        <i className="fa-solid fa-house"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardSidePanel