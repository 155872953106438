import React from 'react'
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../Components/RouteLayout/AuthLayout';
import AppLayout from '../Components/RouteLayout/AppLayout';
import AuthenticatedRoute from './PrivateRoutes/AuthenticatedRoute';
import UnauthenticatedRoute from './PrivateRoutes/UnauthenticatedRoute ';
import NotFound from "../Components/Pages/NotFound/NotFound"

import HomeContainer from '../Components/Pages/Home/Container/HomeContainer'
import SignInForm from '../Components/Pages/Account/Layout/SignInForm';
import SignUpForm from '../Components/Pages/Account/Layout/SignUpForm';
import ForgotPassword from '../Components/Pages/Account/Layout/ForgotPassword';

export default function Routes() {
    return (
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route path="/signin" exact component={SignInForm} />
                    <Route path="/signup" exact component={SignUpForm} />
                    <Route path="/reset" exact component={ForgotPassword} />
                    {/* <Route path="/home" exact component={HomeContainer} /> */}
                    <AuthenticatedRoute path="/app" component={AppLayout} />
                    <Redirect to='/app'/>
                </Switch>
            </div>
        </BrowserRouter>
    )
}
