import React from 'react';
import './JoinUs.css';

const JoinUs = (props) => {
    return (
        <div>
            <div className={"join-us d-flex align-items-center " + (props.activeTab === 'Sender' ? 'join-us-sender' : 'join-us-driver')}>
                <div className="container h-100">
                    <div className="w-50 h-100 d-flex justify-content-lg-center justify-content-start align-items-center align-items-lg-start flex-column">
                        <div className="join-us-header">Earn money on your daily commutes</div>
                        <div>
                            <p className="join-us-subheader">
                                Do you have a car, motorcycle, bicycle or scooter? <br />
                                Join us and start to earn money!{" "}
                            </p>
                        </div>
                        <div>
                            <button className="btn-green">
                                <span className="">Join Us</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinUs;