import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./SignUpForm.css";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { db } from "../../../../Config/firebase";
import Footer from "../../../Footer/Layout/Footer";
import HeaderContainer from "../../../Header/Container/HeaderContainer";

const SignUpForm = () => {
  var UsersDataRef = db.collection("UsersData");
  let defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };

  const history = useHistory();
  const [signupError, setSignUpError] = useState("");
  const [formValues, setFormValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  const validate = values => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    if (!values.firstName) {
      errors.firstName = "Cannot be blank";
    }
    if (!values.lastName) {
      errors.lastName = "Cannot be blank";
    }
    if (!values.phone) {
      errors.phone = "Cannot be blank";
    } else if (values.length > 10 || values.length < 10) {
      errors.phone = "Invalid phone number";
    }
    if (!values.password) {
      errors.password = "Cannot be blank";
    } else if (!uppercaseRegExp.test(values.password)) {
      errors.password = "At least one Uppercase";
    } else if (!lowercaseRegExp.test(values.password)) {
      errors.password = "At least one Lowercase";
    } else if (!digitsRegExp.test(values.password)) {
      errors.password = "At least one digit";
    } else if (!specialCharRegExp.test(values.password)) {
      errors.password = "At least one Special Characters";
    } else if (!minLengthRegExp.test(values.password)) {
      errors.password = "At least minumum 8 characters";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Can not be blank";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Confirm password is not matched";
    }

    return errors;
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setFormErrors(validate(formValues));
  }

  function submitForm() {
    firebase
      .auth()
      .createUserWithEmailAndPassword(formValues.email, formValues.password)
      .then(userCredential => {
        var user = userCredential.user;
        user
          .updateProfile({
            displayName: formValues.firstName + " " + formValues.lastName,
          })
          .then(() => {})
          .catch(error => {});
        UsersDataRef.doc(user.uid)
          .set({
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phone: formValues.phone,
          })
          .then(() => {})
          .catch(e => {});
        history.push("/signin");
      })
      .catch(error => {
        var errorCode = error.code;
        setSignUpError(errorCode);
      });
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formErrors]);

  return (
    <>
      <HeaderContainer />
      <div className="from_section">
        <div className="sign_up_text">
          <p>Sign Up</p>
        </div>
        <form>
          <div className="input-container">
            <input
              type="text"
              value={formValues.email}
              name="email"
              onChange={handleInputChange}
              placeholder="E-mail"
              required
            />
            {formErrors.email && (
              <span className="error">{formErrors.email}</span>
            )}
          </div>

          <div className="input-container">
            <input
              type="text"
              value={formValues.firstName}
              name="firstName"
              onChange={handleInputChange}
              placeholder="First Name"
              required
            />
            {formErrors.firstName && (
              <span className="error">{formErrors.firstName}</span>
            )}
          </div>

          <div className="input-container">
            <input
              type="text"
              value={formValues.lastName}
              name="lastName"
              onChange={handleInputChange}
              placeholder="Last Name"
              required
            />
            {formErrors.lastName && (
              <span className="error">{formErrors.lastName}</span>
            )}
          </div>

          <div className="input-container">
            <input
              type="number"
              value={formValues.phone}
              name="phone"
              onChange={handleInputChange}
              placeholder="Phone"
              required
            />
            {formErrors.phone && (
              <span className="error">{formErrors.phone}</span>
            )}
          </div>

          <div className="input-container">
            <input
              type="password"
              value={formValues.password}
              name="password"
              onChange={handleInputChange}
              placeholder="Password"
              required
            />
            {formErrors.password && (
              <span className="error">{formErrors.password}</span>
            )}
          </div>

          <div className="input-container">
            <input
              type="password"
              value={formValues.confirmPassword}
              name="confirmPassword"
              onChange={handleInputChange}
              placeholder="Confirm Password"
              required
            />
            {formErrors.confirmPassword && (
              <span className="error">{formErrors.confirmPassword}</span>
            )}
          </div>

          <div className="">
            <button onClick={e => handleSubmit(e)}>SIGN UP</button>
          </div>
        </form>
        <div></div>
        <div className="login-error">
          {signupError && <span>{signupError}</span>}
        </div>
        <div className="signup_link">
          <p>Already have an account ?</p>
          <p>
            <Link to="/signin">SignIn</Link>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SignUpForm;
