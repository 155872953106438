import React, { Component } from 'react';
import HeaderLayout from '../Layout/Header';
import '../Layout/Header.css';
import firebase from 'firebase'
import TrackYourOrder from '../Layout/TrackYourOrder/TrackYourOrder';
import JoinUs from '../Layout/JoinUs/JoinUs';
import ContactUs from '../../Pages/Home/Layout/ContactUs/ContactUs';


const defaultJoinUsContactData = {
    contact_name: '',
    contact_email: '',
    phoneNumber: '',
    message: '',
};

class HeaderContainer extends Component {
    state = {
        activeHeader: 'orderDelivery',
        isOpenTrackOrderModal: true,
        isOpenJoinUsModal: true,
        isOpenContactUsModal: true,
        joinUsContactData: defaultJoinUsContactData,
    };

    handleChangeHeaderTab = (tabName) => {
        this.setState({
            activeHeader: tabName,
            isOpenTrackOrderModal: tabName === 'TrackYourOrder',
            isOpenJoinUsModal: tabName === 'JoinUs',
            isOpenContactUsModal: tabName === 'ContactUs'
        });
    };

    handleCloseModal = (key) => {
        this.setState({
            [key]: false,
        });
    };

    signOut = () => {
        firebase.auth().signOut().then(() => {
            localStorage.removeItem("userInfo")
        }).catch((error) => {
            console.log(error)
        });
    }

    handleJoinUsStateChange = (key, value) => {
        this.setState({
            joinUsContactData: {
                ...this.state.joinUsContactData,
                [key]: value,
            }
        })
    };

    render() {
        const { activeHeader, isOpenContactUsModal, isOpenTrackOrderModal, isOpenJoinUsModal } = this.state;
        return (
            <div>
                <HeaderLayout
                    activeHeader={activeHeader}
                    handleChangeHeaderTab={this.handleChangeHeaderTab}
                    signOut={this.signOut}
                />
                {activeHeader === 'TrackYourOrder' && (
                    <TrackYourOrder
                        isOpenModal={isOpenTrackOrderModal}
                        handleCloseModal={this.handleCloseModal}
                    />)}
                {activeHeader === 'JoinUs' && (
                    <JoinUs
                        isOpenModal={isOpenJoinUsModal}
                        handleCloseModal={this.handleCloseModal}
                        handleJoinUsStateChange={this.handleJoinUsStateChange}
                    />)}

                {activeHeader === 'ContactUs' && (
                    <ContactUs
                        isOpenModal={isOpenContactUsModal}
                        handleCloseModal={this.handleCloseModal}
                        handleContactUsStateChange={this.handleContactUsStateChange}
                    />)}
            </div>
        );
    }
}

export default HeaderContainer;