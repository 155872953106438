import React from 'react'
import Dashboard from '../Layout/Dashboard'
import DataTable from '../Layout/DataTable'

export const DashboardContainer = () => {
    return (
        <div>
            <Dashboard />
            <DataTable />
        </div>

    )
}
