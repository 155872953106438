import React from "react";
import {Button, Form} from "react-bootstrap";
import Location from '../../../../../Assets/icon/Location.png'
import arrow_right from '../../../../../Assets/icon/arrow_right.png'
import arrow_left from '../../../../../Assets/icon/arrow_left.png'
import Vector from '../../../../../Assets/icon/Vector.png'
import cart from '../../../../../Assets/icon/cart.png'
import Hour from '../../../../../Assets/icon/Hour.png'
import arrow_up_right from '../../../../../Assets/icon/arrow_up_right.png'
import edit from '../../../../../Assets/icon/edit.png'
import info_circle from '../../../../../Assets/icon/info_circle.png'
import collapse_list  from '../../../../../Assets/icon/collapse_list.png'

const AddressInfoForm = (props) => {
    const { senderPopupData, receiverPopupData } = props;
    return (
        <React.Fragment>
            <div className="hero-form">
                <Form onSubmit={() => {
                }}>

                    <Form.Group controlId="pickupAdd">
                        <img src={Location} alt="location"/>
                        <Form.Control
                            placeholder="Pick Up Address"
                            required
                            type="text"
                            onChange={(e) =>
                                props.handleStateChange("pickupAdd", e.target.value)
                            }
                        />
                        <Form.Label>Pick Up Address</Form.Label>
                    </Form.Group>

                    <Form.Group controlId="dropOffAddress">
                        <img src={Location} alt="location"/>
                        <Form.Control
                            placeholder="Drop Off Address"
                            required
                            type="text"
                            onChange={(e) =>
                                props.handleStateChange("dropOffAddress", e.target.value)
                            }
                        />
                        <Form.Label>Drop Off Address</Form.Label>
                    </Form.Group>

                    <Form.Group controlId="senderDetails" className="position-relative" onClick={() => {}}>
                        <img src={arrow_right} alt="location"/>
                        <div className="form-group detail-inp d-flex">
                            <Form.Control className="bg-transparent"
                                          placeholder="Sender’s Details"
                                          required
                                          type="text"
                                          onChange={(e) =>
                                              props.handleStateChange("senderDetails", e.target.value)
                                          }
                                          onFocus={props.handleToggleSenderDetailPopup}
                                          value={senderPopupData.senderName}
                            />
                            <Form.Control className="ml-2 detail-inp-left pl-0"
                                        placeholder="Sender’s Details"
                                        required
                                        type="text"
                                        onChange={(e) =>
                                            props.handleStateChange("senderDetails", e.target.value)
                                        }
                                        onFocus={props.handleToggleSenderDetailPopup}
                                        value={senderPopupData.senderPhoneNo}
                            />
                            <Form.Label>Sender’s Details</Form.Label>
                        </div>
                        <img src={arrow_up_right} className="arrow_up_right" alt="location"  onClick={props.handleToggleSenderDetailPopup}/>
                    </Form.Group>


                    {props.isOpenSenderModal && (
                        <div className="sender_popup">
                            <img src={collapse_list} className="back" alt="" onClick={props.handleToggleSenderDetailPopup}/>
                            <Form.Group controlId="Sender’s_Name">
                                <Form.Control
                                    placeholder="Sender’s_Name"
                                    required
                                    type="text"
                                    onChange={(e) =>
                                        props.handleSenderPopupStateChange("senderName", e.target.value)
                                    }
                                    value={senderPopupData.senderName}
                                />
                                <Form.Label>Sender’s Name</Form.Label>
                            </Form.Group>

                            <Form.Group controlId="Sender’s_Phone">
                                <Form.Control
                                    placeholder="Sender’s_Phone"
                                    required
                                    type="text"
                                    onChange={(e) =>
                                        props.handleSenderPopupStateChange("senderPhoneNo", e.target.value)
                                    }
                                    value={senderPopupData.senderPhoneNo}
                                />
                                <Form.Label>Sender’s Phone</Form.Label>
                            </Form.Group>

                            <Form.Group controlId="Sender’s_Email">
                                <Form.Control
                                    placeholder="Sender’s_Email"
                                    required
                                    type="text"
                                    onChange={(e) =>
                                        props.handleSenderPopupStateChange("senderEmail", e.target.value)
                                    }
                                    value={senderPopupData.senderEmail}
                                />
                                <Form.Label>Sender’s Email</Form.Label>
                            </Form.Group>

                            <Form.Group controlId="Email_for_invoicing">
                                <Form.Control
                                    placeholder="Email_for_invoicing"
                                    required
                                    type="text"
                                    onChange={(e) =>
                                        props.handleSenderPopupStateChange("emailForInvoicing", e.target.value)
                                    }
                                    value={senderPopupData.emailForInvoicing}
                                />
                                <Form.Label>Email for invoicing</Form.Label>
                            </Form.Group>
                            <div className="info">
                                <p><img src={info_circle} alt="info_circle"/>
                                    Only if it’s different from “Sender’s Email”
                                </p>
                            </div>
                            <div className="switch">
                                <input type="checkbox" id="toggleAll"/>
                                <label htmlFor="toggleAll"/>
                                <span>Save details for later</span>
                            </div>
                        </div>
                    )}



                    <Form.Group controlId="receiverName">
                        <img src={arrow_left} alt="arrow_left"/>
                        <div className="form-group detail-inp d-flex">
                            <Form.Control className="bg-transparent"
                                placeholder="Receiver’s Details"
                                required
                                type="text"
                                onChange={(e) =>
                                    props.handleStateChange("receiverName", e.target.value)
                                }
                                 onFocus={props.handleToggleReceiverDetailPopup}
                                 value={receiverPopupData.receiverName}
                            />
                            <Form.Control className="ml-2"
                                placeholder="Receiver’s Details"
                                required
                                type="text"
                                onChange={(e) =>
                                    props.handleStateChange("receiverName", e.target.value)
                                }
                                onFocus={props.handleToggleReceiverDetailPopup}
                                value={receiverPopupData.receiverPhoneNo}
                            />
                            <Form.Label>Receiver’s Details</Form.Label>
                        </div>
                        <img src={arrow_up_right} className="arrow_up_right" alt="location"  onClick={props.handleToggleReceiverDetailPopup}/>
                    </Form.Group>

                {props.isOpenReceiverModal && (
                 <div className="receiver_popup">
                        <img src={collapse_list} className="back" alt="" onClick={props.handleToggleReceiverDetailPopup}/>
                        <Form.Group controlId="Receiver’s_Name">
                            <Form.Control
                                placeholder="Receiver’s_Name"
                                required
                                type="text"
                                onChange={(e) =>
                                    props.handleReceiverPopupStateChange("receiverName", e.target.value)
                                }
                                value={receiverPopupData.receiverName}
                            />
                            <Form.Label>Receiver’s Name</Form.Label>
                        </Form.Group>

                        <Form.Group controlId="Receiver’s_Phone">
                            <Form.Control
                                placeholder="Receiver’s_Phone"
                                required
                                type="text"
                                onChange={(e) =>
                                    props.handleReceiverPopupStateChange("receiverPhoneNo", e.target.value)
                                }
                                value={receiverPopupData.receiverPhoneNo}
                            />
                            <Form.Label>Receiver’s Phone</Form.Label>
                        </Form.Group>
                    </div>
                )}

                    <Form.Group controlId="selectedPackageFitsFor">
                        <img src={Vector} alt="Vector"/>
                        <Form.Control as="select" defaultValue="Car">
                            <option value="Car">Car</option>
                        </Form.Control>
                        <Form.Label>Package fits for</Form.Label>
                    </Form.Group>


                    <div className="d-flex">
                        <Form.Group className="w-50 m-0" controlId="selectedUpToKG">
                            <img src={cart} alt="cart"/>
                            <Form.Control
                                as="select"
                                defaultValue="3kg"
                                onChange={(e) =>
                                    props.handleStateChange("selectedUpToKG", e.target.value)
                                }
                            >
                                <option value={"3kg"}>3kg</option>
                                <option value={"5kg"}>5Kg</option>
                            </Form.Control>
                            <Form.Label>Up to</Form.Label>
                        </Form.Group>
                        <Form.Group className="w-50 m-0 ml-2" controlId="selectedUpToHours">
                            <img src={Hour} alt="Hour"/>
                            <Form.Control
                                as="select"
                                defaultValue="3 hour"
                                onChange={(e) =>
                                    props.handleStateChange("selectedUpToHours", e.target.value)
                                }
                            >
                                <option value={"3 hour"}>3 hour</option>
                                <option value="5 hour">5 hour</option>
                            </Form.Control>
                            <div className="info mt-3">
                                <p className="m-0">
                                    <img className="d-flex float-left" src={info_circle} alt="info_circle"/>
                                    Lorem ipsum dolor info text
                                </p>
                            </div>
                        </Form.Group>
                    </div>


                    <Form.Group controlId="Type...">
                        <img src={edit} alt="edit"/>
                        <Form.Control
                            placeholder="Type..."
                            required
                            type="text"
                            onChange={(e) =>
                                props.handleStateChange("Type...", e.target.value)
                            }
                        />
                        <Form.Label className="edit">Note to courier</Form.Label>
                    </Form.Group>
                    <div className="info">
                        <p><img src={info_circle} alt="info_circle"/>
                            If no-one is there we will leave the package by the door
                        </p>
                        <h6>Estimated Delivery by <span>2PM</span></h6>
                    </div>
                    <div className="d-flex price">
                        <span>Price:</span>
                        <span>&nbsp;28.00$</span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button type="submit" className="btn-green mt-5 mb-3">
                            SUBMIT OFFER
                        </Button>
                    </div>
                    <div className="info text-center">
                        <p><img src={info_circle} alt="info_circle"/>
                            Your offer will expire in %%pick up time%%
                        </p>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    );
};

export default AddressInfoForm;
