import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./SignInForm.css";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import Footer from "../../../Footer/Layout/Footer";
import HeaderContainer from "../../../Header/Container/HeaderContainer";

const SignInForm = () => {
  let defaultValues = {
    email: "",
    password: "",
  };
  const history = useHistory();
  const [loginError, setLoginError] = useState("");
  const [formValues, setFormValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    setFormErrors(validate(formValues));
  }

  const validate = values => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    if (!values.password) {
      errors.password = "Cannot be blank";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    }
    return errors;
  };

  function submitForm() {
    firebase
      .auth()
      .signInWithEmailAndPassword(formValues.email, formValues.password)
      .then(userCredential => {
        var user = userCredential.user;
        localStorage.setItem("userInfo", JSON.stringify(user));
        localStorage.setItem("kiwi-uid", user.uid);
        history.push("/app/dashboard");
      })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setLoginError(errorCode);
      });

    const user = firebase.auth().currentUser;
    console.log("currentuser", user);
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }
  }, [formErrors]);

  return (
    <>
      <HeaderContainer />
      <div className="body">
        <div className="from_section">
          <h4>KIWI</h4>
          <div className="Para_firts_text">
            <p>Hello! let's get started</p>
          </div>
          <div className="para_second_text">
            <p>Sign In to continue.</p>
          </div>
          <form>
            <div className="input-container">
              <input
                type="text"
                value={formValues.email}
                name="email"
                onChange={handleInputChange}
                placeholder="Username"
                required
              />
              {formErrors.email && (
                <span className="error">{formErrors.email}</span>
              )}
            </div>
            <div className="input-container">
              <input
                type="password"
                value={formValues.password}
                name="password"
                onChange={handleInputChange}
                placeholder="Password"
                required
              />
              {formErrors.password && (
                <span className="error">{formErrors.password}</span>
              )}
            </div>
            <div className="button-container">
              <button onClick={e => handleSubmit(e)}>SIGN IN</button>
            </div>
          </form>
          <div className="main_inputbox_forget">
            <div className="forget_password">
              <a href="/reset">Forgot Password?</a>
            </div>
          </div>

          <div className="login-error">
            {loginError && <span>{loginError}</span>}
          </div>
          <div className="signup_link">
            <p>Don't have an account ?</p>
            <p>
              <Link to="/signup">SignUp</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SignInForm;
