import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDD20AScUy1pghsnWZ4qLqIRyotAJILEOM",
  authDomain: "kiwi-4b020.firebaseapp.com",
  databaseURL: "https://kiwi-4b020-default-rtdb.firebaseio.com",
  projectId: "kiwi-4b020",
  storageBucket: "kiwi-4b020.appspot.com",
  messagingSenderId: "267304901149",
  appId: "1:267304901149:web:7783d727647ef5a72a04f4",
  measurementId: "G-6GMGZ7VSFG"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const database = firebaseApp.database();
export const firebaseFunc = firebase.functions();
export const firestore = firebase.firestore;
// export { auth, database }
