import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { db } from "../../../../Config/firebase";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./Paypal.css";

const PaypalModal = props => {
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("ILS");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setAmount(props?.amount ? props?.amount : 0);
    setCurrency(props?.currency ? props?.currency : "ILS");
    setDescription(
      props?.description ? props?.description : "Kiwi-job-take-in"
    );
  }, [props]);

  function _createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            description: description,
            amount: {
              currency_code: currency,
              value: amount,
            },
          },
        ],
      })
      .then(orderId => {
        // Your code here after create the order
        console.log("-------------orderId-----------", orderId);
        return orderId;
      });
  }

  async function _onApprove(data, actions) {
    // Authorize the transaction
    actions.order
      .authorize()
      .then(function (authorization) {
        // Get the authorization id
        console.log("-------------data-----------", data);
        console.log("-------------authorization-----------", authorization);

        var authorizationID =
          authorization.purchase_units[0].payments.authorizations[0].id;
        const payload = {
          status: authorization.status,
          orderId: data.orderID,
          authorizationId: authorizationID,
        };
        props.onSuccess(payload);
      })
      .catch(err => {
        console.log("----error-------", err);
        let errObj = {
          err: err,
          status: "FAILED",
        };
        props.onFail(errObj);
      });
  }

  function _onError(err) {
    console.log("----error-------", err);
    let errObj = {
      err: err,
      status: "FAILED",
    };
    props.onFail(errObj);
  }
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter1"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter1">PayPal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="paypal_main_form">
            {amount > 0 ? (
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "AQY5cxGJ4XX5Fmcn-J7VCmeHb5Gsbwl4LxA3IUbI7bYmZweikKppF8cwdRcY4DH0MBBKoCYewi3diN9R",
                  currency: "ILS",
                  intent: "authorize",
                }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => _createOrder(data, actions)}
                  onApprove={(data, actions) => _onApprove(data, actions)}
                  onCancel={() => _onError("CANCELED")}
                  onError={err => _onError("ERROE")}
                />
              </PayPalScriptProvider>
            ) : (
              <div style={{ paddingTop: 250, textAlign: 'center', fontSize: 20 }}>Loading...</div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaypalModal;
