import React from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import "./JoinUs.css";
import AppStore from "../../../../Assets/Images/appstore.svg";
import GooglePlay from "../../../../Assets/Images/googleplay.svg";

const JoinUs = (props) => {
    return (
        <div>
            <Modal
                show={props.isOpenModal}
                onHide={() => props.handleCloseModal("isOpenJoinUsModal")}
                backdrop="static"
                keyboard={false}
                centered
                className="kiwi-modal"
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="joinUs-header">
                                    Join us to earn some extra money on the way!
                                </div>
                                <div className="joinUs-subheader">
                                    Do you have a car, motorcycle, bicycle or scooter? Join us and
                                    start to earn money!
                                </div>
                                <div className="store-btn mb-3 text-center text-sm-left">
                                    <img src={AppStore} alt="appstore icon" />
                                </div>
                                <div className="store-btn text-center text-sm-left mb-3 mb-sm-0">
                                    <img src={GooglePlay} alt="googleplay icon" />
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form onSubmit={() => {
                                }}>
                                    <Form.Group controlId="join_contact_name">
                                        <Form.Control
                                            required
                                            type="text"
                                            onChange={(e) =>
                                                props.handleJoinUsStateChange(
                                                    "contact_name",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Name"
                                        />
                                        <Form.Label>Name</Form.Label>
                                    </Form.Group>

                                    <Form.Group controlId="join_contact_email">
                                        <Form.Control
                                            required
                                            type="email"
                                            onChange={(e) =>
                                                props.handleJoinUsStateChange(
                                                    "contact_email",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Email address"
                                        />
                                        <Form.Label>Email address</Form.Label>
                                    </Form.Group>

                                    <Form.Group controlId="join_contact_phoneNumber">
                                        <Form.Control
                                            required
                                            type="text"
                                            onChange={(e) =>
                                                props.handleJoinUsStateChange(
                                                    "phoneNumber",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Phone number"
                                        />
                                        <Form.Label>Phone number</Form.Label>
                                    </Form.Group>


                                    <Form.Group controlId="selectedUpToHours">
                                        <Form.Control
                                            as="select"

                                            defaultValue="3 hour"
                                            onChange={(e) =>
                                                props.handleStateChange("selectedUpToHours", e.target.value)
                                            }
                                        >
                                            <option value={"3 hour"}>3 hour</option>
                                            <option value={"5 hour"}>5 hour</option>
                                        </Form.Control>
                                        <Form.Label>Vehicle type</Form.Label>
                                    </Form.Group>

                                    <Form.Group controlId="contact_captcha">
                                        <div className="captcha justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <div className="spinner">
                                                    <label>
                                                        <input type="checkbox"
                                                            onClick="$(this).attr('disabled','disabled');" />
                                                        <span className="checkmark"><span>&nbsp;</span></span>
                                                    </label>
                                                </div>
                                                <div className="text">
                                                    I'm not a robot
                                                </div>
                                            </div>
                                            <div className="logo">
                                                <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/" alt="captcha" />
                                                <p>reCAPTCHA</p>
                                                <small>Privacy - Terms</small>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Button type="submit" className="btn-green">
                                        SUBMIT
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default JoinUs;
