import React from "react";
import "./AddressInfo.css";
import  AddressInfoForm  from './AddressInfoForm';
import offer_1 from "../../../../../Assets/icon/offer_1.png";
import offer_location from "../../../../../Assets/icon/offer_location.png";
import googleplay from "../../../../../Assets/icon/googleplay.png";
import appstore from "../../../../../Assets/icon/appstore.png";

const AddressInfo = (props) => {
  return (
    <div>
      <div className="hero-section d-flex align-items-center">
        <div className="overlay-bg-black" />
        <div className="container h-100">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-7">
              <div className="hero-header">We get it there cheaper</div>
              <div className="hero-subHeader">
                Connecting people sending packages with drivers heading in the same direction
              </div>
              <div className="d-none d-sm-block">
                <div className="offer_1">
                  <div className="offer-img">
                    <img src={offer_1} alt="offer_1"/>
                  </div>
                  <div className="offer-text">
                    Download the app and get 20% discount on your first order!
                  </div>
                </div>
                <div className="offer_2">
                  <div className="offer-img">
                    <img src={offer_location} alt="offer_location"/>
                  </div>
                  <div className="offer-text">
                    Track your order <br/> from the app!
                  </div>
                </div>
                <div className="download-op">
                  <img src={googleplay} alt="googleplay"/>
                  <img src={appstore} alt="appstore"/>
                </div>
              </div>
              <div className="offer-img-1 d-sm-none d-block">
                <img src={offer_1} alt="offer_1"/>
              </div>
              <div className="d-flex flex-column d-sm-none align-items-center">
                <button className="btn-green">Order Delivery</button>
                <button className="btn-green-outline mt-4">more info</button>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 d-none d-sm-block">
              <AddressInfoForm
                  addressData={props.addressData}
                  handleStateChange={props.handleStateChange}
                  isOpenSenderModal={props.isOpenSenderModal}
                  isOpenReceiverModal={props.isOpenReceiverModal}
                  handleToggleSenderDetailPopup={props.handleToggleSenderDetailPopup}
                  handleToggleReceiverDetailPopup={props.handleToggleReceiverDetailPopup}
                  senderPopupData={props.senderPopupData}
                  receiverPopupData={props.receiverPopupData}
                  handleSenderPopupStateChange={props.handleSenderPopupStateChange}
                  handleReceiverPopupStateChange={props.handleReceiverPopupStateChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
